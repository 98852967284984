@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

html {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
}

* {
  scroll-behavior: smooth;
}

:root {
  --primary-color: #3bb44a;
  --white-color: #ffffff;
  --danger-color: #ff6058;
  --black-color: #000000;
}

/*****Global CSS*****/
html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  background: #f1f1f1;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--primary-color);
}

.btn {
  padding: 13px 15px;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  line-height: 1.4;
  border-radius: 4px;
  overflow: hidden;
}

.btn-bordered {
  border: 1px solid #585858;
}

.btn-bordered {
  position: relative;
}

.btn-bordered:hover {
  border-color: var(--primary-color);
  color: var(--white-color);
}

.btn-bordered::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: transparent;
}

.btn-bordered::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: var(--primary-color);
}

.btn-bordered:hover::after {
  width: 100%;
}

.btn-rounded {
  border-radius: 24px;
}

.btn-link {
  color: var(--primary-color);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-color);
}

.btn-primary {
  background: transparent !important;
  border: none !important;
  position: relative;
}

.btn-primary::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: var(--primary-color);
}

.btn-primary::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.21);
}

.btn-primary:hover::after {
  width: 100%;
}

.btn-delete {
  background: transparent !important;
  border: none !important;
  position: relative;
  color: #484848;
}

.btn-delete::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: #dadada;
}

.btn-delete::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: var(--danger-color);
}

.btn-delete:hover {
  color: var(--white-color);
}

.btn-delete:hover::after {
  width: 100%;
}

.btn-delete-new {
  background: transparent !important;
  border: none !important;
  position: relative;
  color: #fff;
}

.btn-delete-new::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: var(--danger-color);
}

.btn-delete-new::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.23);
}

.btn-delete-new:hover {
  color: var(--white-color);
}

.btn-delete-new:hover::after {
  width: 100%;
}

.btn-cancel {
  background: transparent !important;
  border: none !important;
  position: relative;
  color: #484848;
}

.btn-cancel::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: transparent;
  border: 1px solid #dadada;
  border-radius: inherit;
}

.dark-theme .btn-cancel::before {
  border: 1px solid rgba(255, 255, 255, 0.11);
}

.btn-cancel::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.23);
}

.dark-theme .btn-cancel::after {
  background-color: rgba(255, 255, 255, 0.23);
}

.btn-cancel:hover {
  color: #484848;
}

.dark-theme .btn-cancel:hover {
  color: #fff;
}

.btn-cancel:hover::after {
  width: 100%;
}

.btn-secondary {
  background: transparent !important;
  border: none !important;
  position: relative;
  color: #484848;
}

.btn-secondary::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  background-color: #dadada;
}

.btn-secondary::after {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  -o-transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  transition: width 0.35s cubic-bezier(0.58, 0.3, 0.005, 1);
  width: 0;
  z-index: -1;
  background-color: var(--primary-color);
}

.btn-secondary:hover {
  color: var(--white-color);
}

.btn-secondary:hover::after {
  width: 100%;
}

.btn-primary:disabled {
  opacity: 1;
  background: #dadada !important;
  color: #949494;
}

.btn-primary:disabled::before,
.btn-primary:disabled::after {
  display: none;
}

.btn.danger::after {
  background: var(--danger-color);
}

.text-danger {
  color: var(--danger-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.dark-theme .btn-bordered {
  border-color: #171717;
  color: #949494;
}

.dark-theme .btn-bordered:hover {
  color: var(--white-color);
  border-color: #171717;
}

.btn-danger {
  background: #ff605a;
  color: var(--white-color);
  border: 1px solid var(--danger-color);
}

.btn-danger:hover {
  color: var(--danger-color);
  background: transparent;
}

.btn.btn-rounded {
  width: 100%;
  max-width: 150px;
}

.mb__20 {
  margin-bottom: 20px !important;
}

.mb__30 {
  margin-bottom: 30px !important;
}

.form-control {
  outline: none;
  box-shadow: none !important;
  background: var(--white-color);
  border: 1px solid #c1c7cf;
  border-radius: 5px;
  height: auto;
  padding: 12px 13px;
  line-height: 1.4;
  font-weight: 400;
  font-size: 16px;
  color: #171717;
}

select.form-select {
  outline: none;
  box-shadow: none !important;
  background: var(--white-color);
  border: 1px solid #c1c7cf;
  border-radius: 5px;
  height: auto;
  padding: 12px 13px;
  line-height: 1.4;
  font-weight: 400;
  font-size: 16px;
  color: #171717;
  background-image: url("data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015%27 stroke=%27%23171717%27 stroke-width=%271.5%27 stroke-miterlimit=%2710%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) 50%;
  background-size: 20px;
  padding-right: 40px;
}

select.form-control {
  background-image: url("data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015%27 stroke=%27%23171717%27 stroke-width=%271.5%27 stroke-miterlimit=%2710%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 13px) 50%;
  background-size: 20px;
  padding-right: 40px;
}

.dark-theme select.form-control option {
  color: #000;
}

.dark-theme select.form-control {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015' stroke='%23fff' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
}

.form-control::-webkit-input-placeholder {
  color: #abb8ca;
}

.form-control:-ms-input-placeholder {
  color: #abb8ca;
}

.form-control::placeholder {
  color: #abb8ca;
}

.validation-msg {
  position: absolute;
  background: #feeeee;
  box-shadow: 0px 4px 8px rgb(244, 67, 54, 20%),
    0px 2px 4px rgb(156, 43, 35, 20%);
  border-radius: 4px;
  font-size: 15px;
  color: #f05454;
  padding: 8px 10px;
  z-index: 2;
  top: calc(100% + 9px);
}

.error .form-control {
  border-color: var(--danger-color);
}

.validation-msg::before {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #feeeee;
  position: absolute;
  top: -10px;
}

.form-check {
  padding: 0;
}

.form-check input {
  display: none;
}

.form-check label {
  color: var(--black-color);
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  font-size: 16px;
}

.form-check label::before {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
  border: 1px solid #c1c7cf;
  border-radius: 4px;
}

.form-check input:checked + label::before {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3e%3cpath  d=%27M4.1,12.7 9,17.6 20.3,6.3%27 fill=%27none%27 strokeWidth =%272px%27 stroke=%27white%27/%3e%3c/svg%3e") !important;
}

.forgot-wrap {
  position: absolute;
  top: 18px;
  right: 13px;
  color: #abb8ca;
  cursor: pointer;
  width: 20px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='12' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.2561 3.98435C9.15718 3.98435 8.25808 4.88688 8.25808 5.98997C8.25808 7.09307 9.15718 7.9956 10.2561 7.9956C11.355 7.9956 12.2541 7.09307 12.2541 5.98997C12.2541 4.88688 11.355 3.98435 10.2561 3.98435ZM20.2461 5.95989C20.2461 5.94986 20.2461 5.93983 20.2461 5.9298V5.91978C20.2461 5.90975 20.2461 5.90975 20.2461 5.89972C20.2461 5.88969 20.2461 5.88969 20.2461 5.87966C20.2261 5.71921 20.1462 5.55876 20.0363 5.43843C19.3969 4.63618 18.6377 3.92418 17.8685 3.27235C16.2101 1.86841 14.302 0.685097 12.2041 0.213775C10.9953 -0.0569842 9.76657 -0.0670124 8.54779 0.163634C7.4389 0.364197 6.36997 0.805434 5.37097 1.34695C3.80254 2.19934 2.37397 3.3626 1.12521 4.67629C0.895444 4.92699 0.665674 5.16767 0.455884 5.43843C0.176164 5.78941 0.176164 6.21059 0.455884 6.56157C1.09524 7.36382 1.85449 8.07582 2.62372 8.72765C4.28206 10.1316 6.19015 11.3049 8.28805 11.7862C9.49684 12.057 10.7256 12.067 11.9444 11.8364C13.0533 11.6258 14.1222 11.1845 15.1212 10.643C16.6896 9.79063 18.1182 8.62737 19.367 7.31368C19.5967 7.07301 19.8265 6.82231 20.0363 6.56157C20.1462 6.44124 20.2161 6.29082 20.2461 6.12034C20.2461 6.11031 20.2461 6.11031 20.2461 6.10028C20.2461 6.09025 20.2461 6.09025 20.2461 6.08022V6.0702C20.2461 6.06017 20.2461 6.05014 20.2461 6.04011C20.2461 6.03008 20.2461 6.01003 20.2461 6C20.2461 5.97994 20.2461 5.96992 20.2461 5.95989ZM10.2561 10.0012C8.04829 10.0012 6.26008 8.20619 6.26008 5.98997C6.26008 3.77376 8.04829 1.97872 10.2561 1.97872C12.4639 1.97872 14.2521 3.77376 14.2521 5.98997C14.2521 8.20619 12.4639 10.0012 10.2561 10.0012Z' fill='%23ABB8CA'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.forgot-wrap.text {
  width: 21px;
  height: 14px;
  top: 17px;
  right: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3 6.71C13.48 6.89 13.73 7 14.01 7C14.29 7 14.54 6.89 14.72 6.71L19.71 1.71C19.88 1.53 20 1.28 20 1C20 0.45 19.55 0 19 0C18.72 0 18.47 0.11 18.29 0.29L14 4.58L11.71 2.29C11.54 2.11 11.29 2 11.01 2C10.46 2 10.01 2.45 10.01 3C10.01 3.28 10.12 3.53 10.3 3.71L13.3 6.71ZM20 7.96C20 7.95 20 7.94 20 7.93V7.92C20 7.91 20 7.91 20 7.9C20 7.89 20 7.89 20 7.88C19.98 7.72 19.9 7.56 19.79 7.44C19.35 6.89 18.85 6.39 18.33 5.92L16.13 8.12C15.58 8.66 14.83 9 14.01 9C13.96 9 13.92 8.99 13.87 8.99C13.43 10.73 11.88 12.01 10.01 12.01C7.8 12.01 6.01 10.21 6.01 8C6.01 6.38 6.97 4.99 8.35 4.36C8.14 3.95 8.02 3.49 8.02 3C8.02 2.72 8.07 2.46 8.14 2.2C7.09 2.42 6.07 2.84 5.12 3.35C3.55 4.2 2.12 5.37 0.88 6.68C0.65 6.93 0.42 7.18 0.21 7.44C-0.07 7.79 -0.07 8.21 0.21 8.56C0.85 9.36 1.61 10.08 2.38 10.73C4.04 12.14 5.94 13.31 8.04 13.79C9.25 14.06 10.47 14.08 11.69 13.84C12.8 13.63 13.87 13.19 14.87 12.65C16.44 11.8 17.87 10.63 19.11 9.32C19.34 9.08 19.57 8.83 19.78 8.56C19.89 8.44 19.96 8.29 19.99 8.12C19.99 8.11 19.99 8.11 19.99 8.1C19.99 8.09 19.99 8.09 19.99 8.08V8.07C19.99 8.06 19.99 8.05 19.99 8.04C19.99 8.03 19.99 8.01 19.99 8C20 7.99 20 7.97 20 7.96ZM10.01 10.01C11.04 10.01 11.88 9.22 11.99 8.21C11.96 8.18 11.93 8.15 11.9 8.12L11.89 8.13L9.79 6.02C8.79 6.13 8.02 6.97 8.02 8C8.01 9.11 8.91 10.01 10.01 10.01Z' fill='%233BB44A'/%3E%3C/svg%3E");
}

/******Password Validation*******/
.hide-checklist {
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.custom-password-checklist {
  padding: 15px !important;
}

.custom-password-checklist::before {
  content: "Requirements";
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #171717;
  letter-spacing: 0.1275em;
  margin: 0 0 6px 0;
  display: inline-block;
}

.custom-password-checklist li {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 16px;
}

.custom-password-checklist li.invalid {
  color: var(--danger-color);
}

.custom-password-checklist li.invalid span {
  opacity: 1;
}

.custom-password-checklist li.valid {
  color: #0f8b6c;
}

.custom-password-checklist li .checklist-icon {
  display: none;
}

.custom-password-checklist li::before {
  content: "";
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-password-checklist li.invalid::before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.8486 4.94971L4.94914 14.8492' stroke='%23FF6058' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M14.8486 14.8491L4.94914 4.94963' stroke='%23FF6058' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
}

.custom-password-checklist li.valid::before {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999512 4.43027L5.72567 9L13.9995 1' stroke='%230F8B6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

/******Custom CSS*******/
.site-auth-wrapper {
  min-height: 100vh;
}

.auth-header .logo img {
  max-width: 142px;
}

.auth-header {
  padding: 30px;
}

.auth-form-wrapper {
  padding: 50px 0;
}

/******Auth Form*******/
.auth-form-wrapper .form-heading h3 {
  font-weight: 600;
  font-size: 32px;
  color: var(--black-color);
}

.auth-form-wrapper .form-heading p {
  margin: 0 auto;
  color: #0f2744;
  max-width: 410px;
}

.auth-form-wrapper .form-heading {
  margin: 0 0 40px 0;
}

.auth-form-wrapper .button-wrap {
  margin: 0 0 30px 0;
}

.auth-form-wrapper .button-wrap .btn {
  width: 100%;
  letter-spacing: 0.054em;
  font-weight: 700;
}

.auth-form-wrapper .form-text p {
  font-weight: 600;
  font-size: 16px;
  color: var(--black-color);
  margin: 0;
}

.auth-form-wrapper .form-text {
  margin: 0;
}

.auth-form-wrapper .form-wrapper {
  max-width: 530px;
  margin: 0 auto 30px;
  padding: 30px;
  box-shadow: 0px 8px 24px rgba(21, 21, 22, 0.1);
  border-radius: 8px;
}

.auth-form-wrapper .form-wrapper .form-text {
  color: #000;
  font-size: 12px;
  opacity: 1;
}

.form-text-help {
  font-weight: 400;
  font-size: 12px;
  color: #000;
  opacity: 0.5;
  margin-top: 10px;
}

.auth-form-wrapper .form-wrapper .form-check-label {
  color: var(--black-color);
  font-size: 14px;
}

.auth-form-wrapper .outer-form-text {
  max-width: 530px;
  margin: auto;
  padding: 0 0px;
  text-align: center;
}

.auth-form-wrapper .outer-form-text p {
  color: var(--black-color);
  margin: 0;
}

.form-control:focus {
  border: 1px solid #c1c7cf;
}

.auth-form-wrapper .form-text p a {
  font-weight: 600;
  text-decoration: none;
}

.auth-form-wrapper .form-text p a:hover {
  text-decoration: underline;
}

.auth-form-wrapper .form-wrapper .form-check {
  margin: 0;
}

.auth-form-wrapper .form-wrapper .reset-password {
  font-weight: 600;
  font-size: 14px;
}

.auth-form-wrapper .form-wrapper .reset-password:hover {
  text-decoration: underline;
}

/*************************************
Dashboard Header Starts
**************************************/

.site-header {
  width: 100%;
  background: var(--white-color);
}

.site-main-wrapper {
  min-height: 100vh;
}

.site-header .logo-wrap {
  padding: 15px;
  border-right: 1px solid #dadada;
}

.site-header .logo-wrap .logo {
  margin-right: 20px;
}

.site-header .logo-wrap .camera-dropdown {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  color: var(--black-color);
  padding: 0;
  border: none;
  background: transparent;
  gap: 20px;
  align-items: center;
  min-width: 160px;
  justify-content: space-between;
}

.site-header .left-wrap .logo-wrap .camera-dropdown::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #a9a9a9;
}

.setting-body .site-header .logo-wrap .camera-dropdown::after,
.content-body .site-header .logo-wrap .camera-dropdown::after {
  display: none;
}

.content-body .site-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.content-body .site-header .logo-wrap {
  border-right: none;
}

.site-header .add-device-wrap {
  padding: 0 0 0 0px;
}

.site-header .add-device-wrap .add-device {
  padding: 8px 17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0 60px;
  border: 1px solid #585858;
}

.site-header .add-device-wrap .add-device svg {
  margin-right: 10px;
}

.site-header .add-device-wrap .add-device:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.site-header .add-device-wrap .add-device:hover svg path {
  fill: var(--white-color);
}

.site-header .add-device-wrap .add-device svg path {
  transition: 0.3s;
}

.site-header .add-device-wrap .refresh {
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
}

.refresh-btn {
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  display: flex;
  align-items: center;
}

.site-header .add-device-wrap .refresh svg {
  margin-right: 10px;
}

.refresh-btn svg {
  margin-right: 10px;
}

.site-header .display-wrap .display-inputs .form-checkbox {
  margin-left: 15px;
}

.site-header .display-wrap .display-inputs .form-checkbox input {
  display: none;
}

.site-header
  .display-wrap
  .display-inputs
  .form-checkbox
  input:checked
  + label
  svg
  g {
  opacity: 1;
}

.site-header
  .display-wrap
  .display-inputs
  .form-checkbox
  input:checked
  + label
  path {
  stroke: var(--primary-color) !important;
}

.site-header .display-wrap .display-inputs .form-checkbox label {
  cursor: pointer;
}

.site-header .search-profile-wrap .profile-btn {
  border: none;
  background: transparent;
  padding: 13px 15px;
  border-left: 1px solid #dadada;
  display: flex;
  align-items: center;
  gap: 8px;
}

.site-header .search-profile-wrap .profile-btn img {
  border-radius: 50%;
  object-fit: cover;
  width: 44px;
  height: 44px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 23%);
}

.site-header .search-profile-wrap .profile-btn::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 8px solid #a9a9a9;
}

.site-header .search-profile-wrap .search-wrap {
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-right: 15px;
  min-width: 370px;
}

.site-header .search-profile-wrap .search-wrap .form-control {
  width: 0px;
  transition: 0.4s;
  opacity: 0;
}

.site-header .search-profile-wrap .search-wrap.active .form-control {
  opacity: 1;
  width: 100%;
}

.site-header .search-profile-wrap .search-wrap .form-control {
  background: rgb(245 245 245);
  border-radius: 40px;
  height: auto;
  border: none;
  padding: 11px 20px;
}

.site-header .search-profile-wrap .search-wrap .btn {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  padding: 5px;
  border: none;
}

.site-header .add-device-wrap .refresh.loading svg,
.refresh-btn.loading svg {
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.site-main-wrapper:not(.grid-page) .add-device-wrap,
.site-main-wrapper:not(.grid-page) .display-wrap {
  display: none !important;
}

.site-main-wrapper:not(.grid-page) .site-header {
  justify-content: space-between;
}

/*************************************
Dashboard Header Ends
**************************************/

/*************************************
Dashboard Main Page
**************************************/

.devices-page .add-device {
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #0a0a0a;
  border: 2px solid #484848;
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  margin: auto;
}

.devices-page .add-device:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.devices-page .add-device p {
  margin: 0;
}

.devices-page .add-device svg {
  margin-bottom: 50px;
}

.site-main-wrapper .main-wrapper .main-page-height {
  height: 100%;
  min-height: calc(100vh - 70px);
}

.devices-page .camera-header,
.site-map-view .camera-header {
  padding: 13px 20px;
  border-color: #dadada;
  border-width: 1px 0;
  border-style: solid;
  min-height: 70px;
  position: relative;
}

.devices-page .camera-header::before,
.site-map-view .camera-header::before {
  background: var(--white-color);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}

.devices-page .camera-header .filter-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.devices-page .camera-header .filter-list li button {
  border: none;
  padding: 5px 10px;
  background: transparent;
  font-weight: 400;
  font-size: 20px;
  color: #686868;
}

.devices-page .camera-header .filter-list li button.active {
  color: #171717;
  font-weight: 700;
}

.devices-page .camera-header .filter-list li:not(:last-of-type) button {
  margin-right: 20px;
}

.devices-page .camera-header .dropdown .btnn {
  border-radius: 0;
  padding: 10px 10px;
  border: none;
}

.site-map-view .camera-header .dropdown .btn {
  font-weight: 700;
  font-size: 16px;
  color: #3bb44a;
  display: flex;
  align-items: center;
}

.devices-page .camera-header .dropdown .dropdown-menu,
/* .site-map-view .camera-header .dropdown, .dropdown-menu, */
.table-dropdown.dropdown .dropdown-menu,
.ques-options .dropdown-menu {
  border-radius: 1px;
  min-width: 130px;
}

.devices-page .camera-header .dropdown .dropdown-menu .dropdown-item,
.site-map-view .camera-header .dropdown,
.dropdown-menu .dropdown-item,
.table-dropdown.dropdown .dropdown-menu .dropdown-item,
.ques-options .dropdown-menu .dropdown-item {
  background: transparent !important;
  color: #686868;
}
.dropdown-wrap .dropdown .dropdown-menu .active,
.site-map-view .camera-header .dropdown,
.dropdown-menu .active {
  color: var(--primary-color) !important;
}

.site-map-view .camera-header .dropdown,
.dropdown-menu .dropdown-item .active {
  color: var(--primary-color) !important;
}

.devices-page .camera-header .dropdown .dropdown-menu .dropdown-item:hover,
.site-map-view .camera-header .dropdown,
.dropdown-menu .dropdown-item:hover,
.table-dropdown.dropdown .dropdown-menu .dropdown-item:hover,
.ques-options .dropdown-menu .dropdown-item:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.devices-page .camera-header .dropdown .btnn.show,
.site-map-view .camera-header .dropdown,
.btnn.show {
  background: #f3f3f3;
}

.main-wrapper .camera-list-wrapper {
  padding: 25px;
  max-height: calc(100vh - 143px);
  /* overflow: auto; */
  overflow-x: hidden;
}

.camera-row .card-camera {
  background: var(--white-color);
}

.camera-row .card-camera .card-header {
  background: var(--white-color);
  padding: 0;
  border-radius: 0;
}

.camera-row .card-camera .card-header .camera-status {
  padding: 10px 15px;
  border-right: 1px solid #dadada;
  align-items: center;
  display: flex;
}

.camera-row .card-camera .card-header .camera-details {
  padding: 10px 10px 10px 15px;
}

.camera-row .card-camera .card-header .camera-details .camera-name {
  padding-right: 10px;
  color: #171717;
}

.camera-row .card-camera .card-header .camera-details button {
  padding: 0px 5px;
  border: none;
  background: transparent;
  vertical-align: middle;
  pointer-events: none;
}

.camera-row .card-camera .card-header .camera-details button.active svg g {
  opacity: 1;
  pointer-events: all;
}

.camera-row .card-camera .card-header .camera-details button.active svg g path {
  fill: var(--primary-color);
}

.camera-row .card-camera .card-camera-body {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.camera-row .col-lg-12 .card-camera .card-camera-body {
  padding-bottom: 40%;
}

.camera-row .card-camera .card-camera-body img {
  position: inherit;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
}

.novideo {
  padding-bottom: 57%;
}

/*************************************
Dashboard Main Page Ends
**************************************/

/*************************************
Dashboard Add Device Starts
**************************************/

.add-new-device-page {
  padding: 40px;
  /* height: calc(100vh - 70px); */
  overflow: auto;
}

.add-new-device-page .theme-form-wrapper .slider {
  background: #fff;
}

.get-location.form-text {
  font-weight: 600;
  display: inherit;
  cursor: pointer;
  text-decoration: none;
}

.dark-theme .add-new-device-page .theme-form-wrapper .slider {
  background: #171717;
}

.add-new-device-page .theme-form-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.add-new-device-page .theme-form-wrapper .flex-grow-1 {
  padding-left: 20px;
}

.theme-form-wrapper .col-form-label {
  color: var(--black-color);
}

.add-new-device-page .theme-form-wrapper .col-form-label {
  min-width: 130px;
  max-width: 130px;
  margin-top: 2px;
}

.form-text {
  font-weight: 400;
  font-size: 14px;
  color: #000;
  opacity: 0.5;
  margin-top: 10px;
}

.theme-form-wrapper .form-control {
  background-color: var(--white-color);
  border-radius: 2px;
  border: 1px solid var(--white-color);
  font-size: 20px;
  padding: 10px 13px;
}

.theme-form-wrapper .form-control:focus {
  border: 1px solid #3bb44a;
}

.theme-form-wrapper .form-control::-webkit-input-placeholder {
  color: #949494;
}

.theme-form-wrapper .form-control:-ms-input-placeholder {
  color: #949494;
}

.theme-form-wrapper .form-control::placeholder {
  color: #949494;
}

.theme-form-wrapper hr {
  background: #dadada;
  opacity: 1;
  margin-top: 0;
}

.theme-form-wrapper .sync-device-checkbox {
  margin-bottom: 20px;
}

.theme-form-wrapper .sync-device-checkbox .form-check-label {
  padding-left: 110px;
  background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7474 12.0758L15.7475 12.0757C19.1551 9.13723 23.5012 7.5143 28.0008 7.5C37.168 7.50039 44.7579 14.3144 45.5832 23.0734L45.6202 23.4663L46.011 23.5216C51.3521 24.2764 55.5 28.7442 55.5 34.2055C55.5 40.2002 50.4962 45 44.4045 45H13.2335C6.23893 45 0.5 39.4899 0.5 32.613C0.5 26.692 4.75298 21.7735 10.4048 20.5257L10.735 20.4528L10.7903 20.1192C11.2667 17.2437 13.1294 14.3315 15.7474 12.0758Z' stroke='%23DADADA'/%3E%3Cpath d='M23 35L33 25' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M33 35L23 25' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: left center;
  margin-left: 75px;
  color: inherit;
}

.theme-form-wrapper .sync-device-checkbox .form-check-label::before {
  left: 75px;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
}

.theme-form-wrapper .sync-device-checkbox .form-check-label p {
  font-size: 16px;
  margin: 0;
}

.theme-form-wrapper .sync-device-checkbox .form-check-label span {
  color: #949494;
  font-size: 12px;
}

.theme-form-wrapper .sync-device-checkbox input:checked + .form-check-label {
  background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7474 12.0758L15.7475 12.0757C19.1551 9.13723 23.5012 7.5143 28.0008 7.5C37.168 7.50039 44.7579 14.3144 45.5832 23.0734L45.6202 23.4663L46.011 23.5216C51.3521 24.2764 55.5 28.7442 55.5 34.2055C55.5 40.2002 50.4962 45 44.4045 45H13.2335C6.23893 45 0.5 39.4899 0.5 32.613C0.5 26.692 4.75298 21.7735 10.4048 20.5257L10.735 20.4528L10.7903 20.1192C11.2667 17.2437 13.1294 14.3315 15.7474 12.0758Z' fill='white' stroke='%233BB44A'/%3E%3Cpath d='M20 30L25.3271 36L36 24' stroke='%233BB44A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.add-new-device-page .map-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

.add-new-device-page .map-wrap iframe {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.add-new-device-page .map-wrap .leaflet-container {
  height: 100%;
  min-height: 500px;
  vertical-align: middle;
}

.add-new-device-page .theme-form-wrapper .btn {
  width: 100%;
  max-width: 310px;
  font-weight: 400;
}

.group-checkbox .group-checkbox-wrap .form-check-label {
  border: 1px solid #dadada;
  border-radius: 27px;
  padding: 12px 20px;
  font-size: 16px;
  background: transparent;
  color: #000;
}

.group-checkbox .group-checkbox-wrap .form-check {
  position: relative;
}

.group-checkbox .group-checkbox-wrap .form-check.recently-added .remove-btn {
  position: absolute;
  top: 50%;
  background: transparent;
  border: none;
  padding: 0;
  height: 15px;
  width: 15px;
  transform: translateY(-50%);
  right: 15px;
}

.group-checkbox .group-checkbox-wrap .form-check {
  margin-right: 12px;
  margin-bottom: 6px;
  margin-top: 6px;
  position: relative;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check.recently-added
  .form-check-label {
  padding-right: 40px;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check.recently-added
  .remove-btn
  svg {
  vertical-align: top;
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  .form-check.recently-added
  .remove-btn
  svg
  path {
  stroke: #fff;
}

.group-checkbox .group-checkbox-wrap .form-check-label::before {
  display: none;
}

.group-checkbox .group-checkbox-wrap input:checked + .form-check-label {
  color: var(--primary-color);
  font-weight: 600;
  background: var(--white-color);
  border-color: var(--white-color);
}

.group-checkbox .group-checkbox-wrap .form-check .input-group .form-control {
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 50px;
  border: 1px solid var(--primary-color);
  border-radius: 27px;
  background: transparent;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .input-group-text {
  position: absolute;
  border: none;
  background: transparent;
  border-radius: 27px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  z-index: 9;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .input-group-text
  .btn {
  padding: 6px 15px;
  position: relative;
  border: none;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #484848;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control:-ms-input-placeholder {
  color: #484848;
}

.group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control::placeholder {
  color: #484848;
}

.group-checkbox .group-checkbox-wrap .form-check .add-btn {
  margin: 0;
  background: transparent;
  border: 1px solid var(--primary-color);
  border-radius: 27px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-theme
  .sidebar-wrapper
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .form-check-label {
  background: #dadada;
  color: #686868;
}

.sidebar-theme
  .sidebar-wrapper
  .group-checkbox
  .group-checkbox-wrap
  input:checked
  + .form-check-label {
  background: #f3f3f3;
  color: var(--primary-color);
}

.sidebar-theme
  .sidebar-wrapper
  .group-checkbox
  .group-checkbox-wrap
  .form-check {
  margin-right: 10px;
}

.set-custom .setting-icon {
  padding: 0;
  border: none;
  background: transparent;
}

.sidebar-theme .sidebar-wrapper .cameras-list .camera-wrap {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.dark-theme .sidebar-theme .sidebar-wrapper .cameras-list .camera-wrap {
  border-bottom: 1px solid #373737;
}

/*************************************
Dashboard Add Device Ends
**************************************/

/*************************************
Dashboard Left Sidebar Starts
**************************************/
.body-overlay.hidden {
  display: none;
}

.body-overlay {
  position: fixed;
  background: linear-gradient(
    90deg,
    var(--white-color) 0%,
    hsl(0deg 0% 100% / 70%) 10%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  z-index: 20;
  width: 100%;
  height: calc(100vh - 70px);
  display: none;
  top: 70px;
  left: 0;
  cursor: pointer;
}

.body-overlay.block {
  display: block;
}

.right-overlay {
  background: linear-gradient(
    270deg,
    var(--white-color) 0%,
    hsl(0deg 0% 100%) 10%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 100%
  );
}

.left-navigation-menu {
  position: fixed;
  background: var(--white-color);
  z-index: 21;
  width: 300px;
  min-height: calc(100vh - 70px);
  top: 70px;
  box-shadow: 20px 40px 40px rgb(62 61 61 / 22%);
  transition-duration: 300ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
}

.translate-x-full {
  transform: translateX(-100%);
}

.translate-x-0 {
  transform: translateX(0px);
}

.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-text {
  color: #686868;
  font-size: 20px;
  font-weight: 400 !important;
  margin: 0 !important;
}

.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option
  .icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 40px;
  background-size: contain;
  background-position: center;
}

.icon.camera {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.75 38.5H26.25C35 38.5 38.5 35 38.5 26.25V15.75C38.5 7 35 3.5 26.25 3.5H15.75C7 3.5 3.5 7 3.5 15.75V26.25C3.5 35 7 38.5 15.75 38.5Z' stroke='%23A9A9A9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.9258 21.0001V18.4101C15.9258 15.0676 18.2883 13.7201 21.1758 15.3826L23.4158 16.6776L25.6558 17.9726C28.5433 19.6351 28.5433 22.3651 25.6558 24.0276L23.4158 25.3226L21.1758 26.6176C18.2883 28.2801 15.9258 26.9151 15.9258 23.5901V21.0001Z' stroke='%23A9A9A9' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.side-navigation-panel-select-option-selected .icon.camera,
.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.camera {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM25.655 24.0275L23.415 25.3225L21.175 26.6175C18.2875 28.28 15.925 26.915 15.925 23.59V21V18.41C15.925 15.0675 18.2875 13.72 21.175 15.3825L23.415 16.6775L25.655 17.9725C28.5425 19.635 28.5425 22.365 25.655 24.0275Z' fill='%23484848'/%3E%3C/svg%3E");
}

.icon.recordings {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9275 35.7349H10.8675C5.3375 35.7349 3.5 32.0599 3.5 28.3674V13.6324C3.5 8.10239 5.3375 6.26489 10.8675 6.26489H21.9275C27.4575 6.26489 29.295 8.10239 29.295 13.6324V28.3674C29.295 33.8974 27.44 35.7349 21.9275 35.7349Z' stroke='%23A9A9A9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M34.1599 29.925L29.2949 26.5125V15.47L34.1599 12.0575C36.5399 10.395 38.4999 11.41 38.4999 14.3325V27.6675C38.4999 30.59 36.5399 31.605 34.1599 29.925Z' stroke='%23A9A9A9' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.125 19.25C21.5747 19.25 22.75 18.0747 22.75 16.625C22.75 15.1753 21.5747 14 20.125 14C18.6753 14 17.5 15.1753 17.5 16.625C17.5 18.0747 18.6753 19.25 20.125 19.25Z' stroke='%23A9A9A9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.side-navigation-panel-select-option-selected .icon.recordings,
.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.recordings {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.0125 10.7975C36.295 10.4125 34.79 10.01 32.7425 11.445L30.17 13.265C29.9775 7.8225 27.615 5.6875 21.875 5.6875H11.375C5.39 5.6875 3.0625 8.015 3.0625 14V28C3.0625 32.025 5.25 36.3125 11.375 36.3125H21.875C27.615 36.3125 29.9775 34.1775 30.17 28.735L32.7425 30.555C33.8275 31.325 34.7725 31.57 35.525 31.57C36.1725 31.57 36.68 31.3775 37.0125 31.2025C37.73 30.835 38.9375 29.8375 38.9375 27.335V14.665C38.9375 12.1625 37.73 11.165 37.0125 10.7975ZM19.25 19.915C17.4475 19.915 15.96 18.445 15.96 16.625C15.96 14.805 17.4475 13.335 19.25 13.335C21.0525 13.335 22.54 14.805 22.54 16.625C22.54 18.445 21.0525 19.915 19.25 19.915Z' fill='%23484848'/%3E%3C/svg%3E");
}

.icon.groups {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.8125 14V4H28.3325C31.4181 4 33.8207 4.9178 35.4514 6.54855C37.0822 8.1793 38 10.5819 38 13.6675V14V15.3125V23.5625H22.8125V15.3125V14Z' stroke='%23A9A9A9'/%3E%3Cpath d='M4 26.6875V18.4375H19.1875V26.6875V27.5625V38H13.6675C10.5819 38 8.1793 37.0822 6.54855 35.4514C4.9178 33.8207 4 31.4181 4 28.3325V27.5625V26.6875Z' stroke='%23A9A9A9'/%3E%3Cpath d='M19.1875 4V14.8125H4V13.6675C4 10.5819 4.9178 8.1793 6.54855 6.54855C8.1793 4.9178 10.5819 4 13.6675 4H19.1875Z' stroke='%23A9A9A9'/%3E%3Cpath d='M38 27.1875V28.3325C38 31.4181 37.0822 33.8207 35.4514 35.4514C33.8207 37.0822 31.4181 38 28.3325 38H22.8125V27.1875H38Z' stroke='%23A9A9A9'/%3E%3C/svg%3E");
}

.side-navigation-panel-select-option-selected .icon.groups,
.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.groups {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.3325 3.5H22.3125V14V15.3125V24.0625H38.5V15.3125V14V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5Z' fill='%23484848'/%3E%3Cpath d='M3.5 17.9375V26.6875V27.5625V28.3325C3.5 34.7025 7.2975 38.5 13.6675 38.5H19.6875V27.5625V26.6875V17.9375H3.5Z' fill='%23484848'/%3E%3Cpath d='M19.6875 3.5V15.3125H3.5V13.6675C3.5 7.2975 7.2975 3.5 13.6675 3.5H19.6875Z' fill='%23484848'/%3E%3Cpath d='M38.5 26.6875V28.3325C38.5 34.7025 34.7025 38.5 28.3325 38.5H22.3125V26.6875H38.5Z' fill='%23484848'/%3E%3C/svg%3E");
}

.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover,
.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option.side-navigation-panel-select-option-selected {
  border-color: rgb(59, 180, 74) !important;
  background: #f3f3f3;
}

.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .side-navigation-panel-select-option-text,
.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option.side-navigation-panel-select-option-selected
  .side-navigation-panel-select-option-text {
  color: var(--black-color);
}

.left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option {
  border-color: #ffffff !important;
  border-width: 0 3px 0 0px !important;
  padding: 30px 40px !important;
  text-decoration: none;
}

/*************************************
Dashboard Left Sidebar Ends
**************************************/

/*************************************
Dashboard Right Sidebar Starts
**************************************/
.right-navigation-menu {
  position: fixed;
  background: var(--white-color);
  z-index: 20;
  width: 320px;
  min-height: calc(100vh - 70px);
  top: 70px;
  box-shadow: 20px 40px 40px rgba(62, 61, 61, 0.22);
  transition-duration: 300ms;
  right: 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.translate-right-full {
  transform: translateX(100%);
}

.translate-right-0 {
  transform: translateX(0);
}

.right-navigation-menu .account-wrap {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 24px;
}

.right-navigation-menu .account-wrap p {
  font-size: 14px;
  color: #484848;
}

.right-navigation-menu .account-wrap .account-wrapper ul {
  padding: 0;
  margin: 0;
}

.account-wrapper ul li {
  background: #f3f3f3;
  border-radius: 6px;
  overflow: hidden;
  padding: 15px 15px;
}

.account-wrapper ul li .profile-wrap img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.account-wrapper ul li .profile-name {
  padding: 0 15px;
  color: #949494;
}

.account-wrapper ul li:not(:last-of-type) {
  margin-bottom: 10px;
}

.right-navigation-menu .account-wrap [class*="icon"] {
  width: 24px;
  height: 24px;
  background-position: center;
}

.right-navigation-menu .account-wrap .setting-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5703 18.5001V14.6001' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5703 7.45V5.5' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15.5707 12.65C17.0066 12.65 18.1707 11.4859 18.1707 10.05C18.1707 8.61401 17.0066 7.44995 15.5707 7.44995C14.1348 7.44995 12.9707 8.61401 12.9707 10.05C12.9707 11.4859 14.1348 12.65 15.5707 12.65Z' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.42969 18.5V16.55' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.42969 9.4V5.5' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.43008 16.5501C9.86602 16.5501 11.0301 15.386 11.0301 13.9501C11.0301 12.5142 9.86602 11.3501 8.43008 11.3501C6.99414 11.3501 5.83008 12.5142 5.83008 13.9501C5.83008 15.386 6.99414 16.5501 8.43008 16.5501Z' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap:hover .setting-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM7.67 5.5C7.67 5.09 8.01 4.75 8.42 4.75C8.83 4.75 9.17 5.09 9.17 5.5V9.4C9.17 9.81 8.83 10.15 8.42 10.15C8.01 10.15 7.67 9.81 7.67 9.4V5.5ZM9.52282 16.4313C9.31938 16.5216 9.17 16.7132 9.17 16.9358V18.5C9.17 18.91 8.83 19.25 8.42 19.25C8.01 19.25 7.67 18.91 7.67 18.5V16.9358C7.67 16.7132 7.5206 16.5216 7.31723 16.4311C6.36275 16.0064 5.7 15.058 5.7 13.95C5.7 12.45 6.92 11.22 8.42 11.22C9.92 11.22 11.15 12.44 11.15 13.95C11.15 15.0582 10.4791 16.0066 9.52282 16.4313ZM16.33 18.5C16.33 18.91 15.99 19.25 15.58 19.25C15.17 19.25 14.83 18.91 14.83 18.5V14.6C14.83 14.19 15.17 13.85 15.58 13.85C15.99 13.85 16.33 14.19 16.33 14.6V18.5ZM15.58 12.77C14.08 12.77 12.85 11.55 12.85 10.04C12.85 8.93185 13.5209 7.98342 14.4772 7.55873C14.6806 7.46839 14.83 7.27681 14.83 7.05421V5.5C14.83 5.09 15.17 4.75 15.58 4.75C15.99 4.75 16.33 5.09 16.33 5.5V7.06421C16.33 7.28681 16.4794 7.47835 16.6828 7.56885C17.6372 7.9936 18.3 8.94195 18.3 10.05C18.3 11.55 17.08 12.77 15.58 12.77Z' fill='%233BB44A'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap .help-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 16L12 11' stroke='%23484848' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.0059 8L11.9969 8' stroke='%23484848' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap:hover .help-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z' fill='%233BB44A'/%3E%3Cpath d='M12 16L12 11' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.0054 8L11.9964 8' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap .report-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2305 20.77L20.7705 17.23' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.7705 20.77L17.2305 17.23' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7 10.5V13.5' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 10.5V13.5' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17 10.5V13.5' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap:hover .report-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.97 7.21C21.69 4.48 19.52 2.31 16.79 2.03C16.59 2.01 16.39 2 16.19 2H7.81C7.61 2 7.41 2.01 7.21 2.03C4.48 2.31 2.31 4.48 2.03 7.21C2.01 7.41 2 7.61 2 7.81V16.19C2 16.39 2.01 16.59 2.03 16.79C2.31 19.52 4.48 21.69 7.21 21.97C7.41 21.99 7.61 22 7.81 22H14C14.55 22 15 21.55 15 21V18.03C15 16.36 16.36 15 18.03 15H21C21.55 15 22 14.55 22 14V7.81C22 7.61 21.99 7.41 21.97 7.21ZM7.75 13.5C7.75 13.91 7.41 14.25 7 14.25C6.59 14.25 6.25 13.91 6.25 13.5V10.5C6.25 10.09 6.59 9.75 7 9.75C7.41 9.75 7.75 10.09 7.75 10.5V13.5ZM12.75 13.5C12.75 13.91 12.41 14.25 12 14.25C11.59 14.25 11.25 13.91 11.25 13.5V10.5C11.25 10.09 11.59 9.75 12 9.75C12.41 9.75 12.75 10.09 12.75 10.5V13.5Z' fill='%233BB44A'/%3E%3Cpath d='M20.97 16H18.03C16.76 16 16 16.76 16 18.03V20.97C16 22.24 16.76 23 18.03 23H20.97C22.24 23 23 22.24 23 20.97V18.03C23 16.76 22.24 16 20.97 16ZM21.47 20.56C21.72 20.81 21.72 21.22 21.47 21.47C21.34 21.59 21.17 21.65 21.01 21.65C20.85 21.65 20.68 21.59 20.55 21.47L19.49 20.41L18.45 21.47C18.32 21.59 18.16 21.65 17.98 21.65C17.83 21.65 17.66 21.59 17.54 21.47C17.29 21.22 17.29 20.81 17.54 20.56L18.6 19.5L17.54 18.45C17.29 18.2 17.29 17.79 17.54 17.54C17.79 17.29 18.2 17.29 18.45 17.54L19.49 18.6L20.55 17.54C20.8 17.29 21.22 17.29 21.47 17.54C21.72 17.79 21.72 18.2 21.47 18.45L20.41 19.5L21.47 20.56Z' fill='%233BB44A'/%3E%3C/svg%3E");
}

.right-navigation-menu .account-wrap:not(:first-child) {
  padding-top: 24px;
}

.right-navigation-menu .account-wrap .title {
  color: #484848;
  transition: 0.3s;
}

.dark-theme .right-navigation-menu .account-wrap .title {
  color: #ffffff;
}

.right-navigation-menu .account-wrap a {
  text-decoration: none;
  color: inherit;
}

.right-navigation-menu .account-wrap {
  text-decoration: none;
}

.right-navigation-menu .account-wrap:hover .title {
  color: rgb(59, 180, 74);
}

.right-navigation-menu .signout {
  margin-top: 25px;
}

.right-navigation-menu .signout .signout-btn {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 18px;
  text-align: center;
  color: var(--danger-color);
}

.right-navigation-menu .signout .signout-btn svg {
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f3f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #949494;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.switch input:checked + .slider::before {
  background: var(--primary-color);
}

.right-navigation-menu .menu-bottom .menu-link {
  margin: 0 0 20px 0;
  padding: 0;
  list-style-type: none;
}

.right-navigation-menu .menu-bottom .menu-link li {
  margin-right: 30px;
}

.right-navigation-menu .menu-bottom .menu-link li a {
  text-decoration-line: underline;
  color: #373737;
  font-size: 14px;
  transition: 0.3s;
}

.right-navigation-menu .menu-bottom .text-wrap {
  font-weight: 400;
  font-size: 14px;
  color: #373737;
  margin: 0;
}

.dark-theme .right-navigation-menu .menu-bottom .menu-link li a,
.dark-theme .right-navigation-menu .menu-bottom .text-wrap {
  color: #ffffff;
}

.right-navigation-menu .menu-bottom .menu-link li a:hover {
  color: var(--primary-color) !important;
}

.right-navigation-menu .account-top-scroll {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/*************************************
Dashboard Right Sidebar Starts
**************************************/

/*************************************
Content pages Starts
**************************************/

.content-page .page-title {
  padding: 25px;
  background: var(--white-color);
  border-width: 1px 0px;
  border-style: solid;
  border-color: #dadada;
}

.content-page .page-title h1 {
  font-weight: 600;
  font-size: 36px;
  color: #171717;
  margin: 0;
}

.content-page .page-title p {
  margin: 20px auto 10px;
  max-width: 1110px;
}

.content-page .content-page-wrapper .section-text {
  color: #565656;
}

.content-page .content-page-wrapper {
  padding: 60px 0;
}

.content-page .section-title {
  margin: 0 0 35px;
}

.content-page .section-title h2 {
  font-weight: 600;
  font-size: 36px;
  color: #171717;
}

.content-page .faq-accordion-wrap {
  border-top: 1px solid #676767;
}

.content-page .faq-accordion-wrap .accordion-item {
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: #676767;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 0;
}

.content-page .faq-accordion-wrap .accordion-item .accordion-button {
  background: transparent;
  box-shadow: none;
  padding: 10px 0;
  font-weight: 400;
  font-size: 22px;
  color: #171717;
}

.content-page .faq-accordion-wrap .accordion-item .accordion-body {
  padding: 0 0 15px 0;
  color: #676767;
}

.content-page .faq-accordion-wrap .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 21H31.5' stroke='%233BB44A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
}

.content-page
  .faq-accordion-wrap
  .accordion-item
  .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 21H31.5' stroke='%233BB44A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 31.5V10.5' stroke='%233BB44A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.content-page .section-faq,
.content-page .section-report {
  margin-bottom: 60px;
}

.content-page .section-title p {
  font-size: 20px;
  color: #171717;
  opacity: 0.5;
}

.content-page .section-form .form-wrapper {
  max-width: 500px;
}

.content-page .section-form .form-wrapper .form-control {
  font-size: 16px;
  padding: 13px 13px;
}

.content-page .section-form .form-wrapper .button-wrap {
  padding-top: 10px;
}

.content-page .section-form .form-wrapper .button-wrap .btn {
  width: 100%;
  max-width: 140px;
}

.content-page .report-checklist .form-check {
  padding-bottom: 10px;
  max-width: 1000px;
}

.content-page .report-checklist .form-check .form-check-label {
  padding-left: 2.7rem;
}

.content-page .report-checklist .form-check .form-check-label h6 {
  font-weight: 400;
  font-size: 24px;
  color: #171717;
}

.content-page .report-checklist .form-check .form-check-label::before {
  top: 0px;
  border: 2px solid var(--primary-color);
  width: 28px;
  height: 28px;
  border-radius: 8px;
}

.content-page .report-checklist .form-check .form-check-label p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #565656;
}

.content-page .section-form .form-wrapper textarea.form-control {
  border: none;
}

.content-page .section-form .form-wrapper.something-else-wrap {
  max-width: 650px;
}

.terms-row {
  position: relative;
}

.terms-row .sidebar-left {
  width: 430px;
  position: sticky;
  top: 50px;
  z-index: 2;
  height: calc(100vh - 17rem);
  padding-top: 25px;
}

.terms-row .flex-grow-1 {
  max-width: calc(100% - 430px);
}

.terms-row .sidebar-left ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.terms-row .sidebar-left ul li:not(:last-of-type) {
  margin-bottom: 15px;
}

.terms-row .sidebar-left ul li a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #656565;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.terms-row .sidebar-left ul li a.active {
  font-weight: 700;
  color: var(--primary-color);
}

.content-page .content-page-wrapper .section-text.element {
  padding-top: 20px;
}

.content-page .content-page-wrapper .section-text.element h4 {
  color: #171717;
}

/*************************************
Content pages Ends
**************************************/

/*************************************
Settings pages Starts
**************************************/

.settings-header {
  padding: 10px 15px;
  background: var(--white-color);
  border-color: #dadada;
  border-width: 1px 0;
  border-style: solid;
  overflow: auto;
}

.settings-header ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.settings-header ul li a svg {
  width: 20px;
  height: 20px;
}

.settings-header ul li {
  margin-right: 10px;
}

.settings-header ul li:last-of-type {
  padding-right: 50px;
}

.settings-header::before {
  content: "";
  display: block;
  width: 75px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
  pointer-events: none;
}

.dark-theme .settings-header::before {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #171717);
}

.settings-header ul li a {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  color: #676767;
  padding: 15px 18px;
  text-decoration: none;
  gap: 12px;
  border-radius: 6px;
  transition: 0.3s;
  white-space: nowrap;
}

.settings-header ul li a.active,
.settings-header ul li a:hover {
  background: #f3f3f3;
  color: var(--black-color);
}

.settings-header ul li a.active svg path,
.settings-header ul li a:hover svg path {
  stroke: var(--primary-color);
}

.account-form .col-form-label {
  min-width: 90px;
  max-width: 90px;
  margin-top: 2px;
}

.profile-wrapper .theme-form-wrapper .flex-grow-1 {
  padding-left: 20px;
}

.profile-wrapper .theme-form-wrapper .btn,
.btn.btn-300 {
  width: 100%;
  max-width: 310px;
}

.setting-page-wrapper {
  padding: 65px 0;
  height: calc(100vh - 147px);
  overflow: auto;
}

.setting-page-wrapper .profile-wrapper .profile-image-area {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 30px 20px;
}

.setting-page-wrapper .profile-wrapper .profile-image-area .user-icon {
  position: relative;
  display: inline-block;
  margin-bottom: 100px;
}

.setting-page-wrapper .profile-wrapper .profile-image-area .user-icon img {
  width: 235px;
  height: 235px;
  border-radius: 50%;
  object-fit: cover;
}

.setting-page-wrapper .profile-wrapper .profile-image-area .user-icon label {
  position: absolute;
  cursor: pointer;
  height: 60px;
  background: #c5c5c5;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  bottom: 0;
  right: 0;
  transition: 0.3s;
}

.setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .user-icon
  label:hover {
  background-color: var(--primary-color) !important;
}

.setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .user-icon
  label
  svg
  path {
  transition: 0.3s;
}

.setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .user-icon
  label:hover
  svg
  path {
  stroke: var(--white-color) !important;
}

.setting-page-wrapper .profile-wrapper .profile-image-area::before {
  content: "";
  width: 1px;
  height: 100%;
  display: inline-block;
  background: #dadada;
}

.setting-page-wrapper .profile-wrapper .account-form {
  padding: 30px 20px;
}

.setting-page-wrapper .profile-wrapper .profile-image-area .button-wrap {
  text-align: center;
}

.setting-page-wrapper .profile-wrapper .profile-image-area .button-wrap .btn {
  width: 100%;
  max-width: 195px;
}

.setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .button-wrap
  .btn:hover {
  border-color: var(--danger-color);
}

/*************************************
Settings pages ends
**************************************/

/*************************************
Billable Subscription Starts
**************************************/
.subscription-group .subscription-box {
  margin-bottom: 30px;
  padding: 25px;
  position: relative;
}

.subscription-group .subscription-box.default-address {
  border: 1px solid var(--primary-color);
}

.subscription-group .subscription-box .heading {
  border-bottom: 1px solid #dadada;
  padding-bottom: 20px;
}

.subscription-group .subscription-box .heading h4 small {
  font-size: 65%;
  font-weight: 500;
}

.address-list .subscription-box {
  height: 100%;
  margin: 0;
}

.subscription-group .subscription-box .button-wrap .btn {
  width: auto;
  max-width: inherit;
  padding: 13px 30px;
}

.subscription-group .subscription-box .heading h4 {
  font-weight: 700;
  font-size: 20px;

  /* identical to box height, or 20px */
  color: #000000;
}

.subscription-group .subscription-box .data-wrapper {
  margin: 0 0 30px 0;
}

.subscription-group .subscription-box .data-wrapper img {
  margin: 10px 0 0 0;
  width: 95%;
  height: 300px;
  border: none;
}

.subscription-group .subscription-box .data-wrapper .site-img {
  margin: 10px 0 0 0;
  width: 100%;
  height: 100%;
  border: none;
  max-height: 300px;
}

.subscription-group .subscription-box .data-wrapper .data-list {
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding: 15px 0;
  color: #171717;
}

.subscription-group .subscription-box .data-wrapper .data-list .title {
  flex-grow: 1 !important;
  font-weight: 600;
}

.subscription-group .subscription-box .data-wrapper .data-list .date-wrap {
  width: 140px;
}

.subscription-group .subscription-box::before {
  background: #fff;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
}

.subscription-group .subscription-box .button-wrap {
  margin: 0 0 5px 0;
}

/*************************************
Billable Subscription ends
**************************************/

/*************************************
Account Settings pages Starts
**************************************/

/*************************************
Device and Storage Starts
**************************************/
.devices-storage-wrapper {
}

.devices-storage-wrapper .devices-storage-list {
  margin-bottom: 20px;
  padding: 30px;
  color: #171717;
  position: relative;
}

.devices-storage-wrapper .devices-storage-list::before {
  background: #fff;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  position: absolute;
}

.devices-storage-wrapper .devices-storage-list .data-list:not(:last-of-type) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dadada;
}

.devices-storage-wrapper .devices-storage-list .data-list .data {
  min-width: 70px;
}

.devices-storage-wrapper .devices-storage-list .data-list .title {
  font-weight: 600;
}

.devices-storage-wrapper .devices-storage-list .data-list {
  padding-left: 10px;
  padding-right: 10px;
}

/*************************************
Device and Storage Ends
**************************************/

.acccount-details-form.profile-wrapper .theme-form-wrapper .flex-grow-1 {
  max-width: 430px;
}

/*************************************
Account Settings pages Ends
**************************************/

/*************************************
Account Permissions Starts
**************************************/

.setting-page-wrapper .back-btn {
  text-decoration: none;
  color: #484848;
  gap: 6px;
  margin-bottom: 20px;
}

.user-permissions .create-user .create-user-wrapper::before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  content: "";
  z-index: -3;
  background: #fff;
  left: 0;
  top: 0;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .button-wrap {
  padding-top: 10px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .find-button-wrap {
  margin-left: 1.5rem;
  width: 150px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .find-button-wrap
  > button {
  width: 100%;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .button-wrap
  .btn-primary {
  max-width: 200px;
}

.user-permissions .page-heading h3 {
  font-weight: 400;
  font-size: 24px;
  color: #171717;
}

.user-permissions .page-heading h3 small {
  font-size: 60%;
  font-weight: 500;
}

.user-permissions .page-heading {
  margin: 0 0 20px 0;
}

.user-permissions .create-user {
  margin: 0 0 40px 0;
}

.user-permissions .create-user .create-user-wrapper {
  padding: 25px 35px;
  max-width: 1100px;
  position: relative;
}

.user-permissions .existing-user {
  max-width: 1100px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-control {
  font-size: 16px;
  padding: 13px 13px;
  background-color: #f1f1f1;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-control:focus {
  background-color: transparent;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-check
  .form-check-label::before {
  border: 1px solid #3bb44a;
  border-radius: 2px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-check {
  padding-top: 10px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .btn-primary:disabled {
  background: #b9b7b7 !important;
  color: #484848;
}

.user-permissions .existing-user {
  max-width: 1100px;
}

.existing-user
  .subscription-group
  .subscription-box
  .data-wrapper
  .data-list
  .date-wrap {
  width: 100px;
  word-break: break-word;
}

.existing-user .subscription-group .subscription-box .heading p {
  margin: 0;
  color: #585858;
  font-size: 14px;
}

.ResellersSystemIntegrators.devices-storage-wrapper
  .devices-storage-list
  .data-list
  .data {
  width: 220px;
}

/*************************************
Account Permissions Ends
**************************************/

/*************************************
Report and Analytics Starts
**************************************/

.report-analytics {
  border-top: 1px solid #dadada;
}

.report-analytics .report-analytics-wrap {
  border-bottom: 1px solid #dadada;
  align-items: center;
  padding: 14px 0;
  color: #171717;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  transition: color 0.3s;
}

.report-analytics .report-analytics-wrap:hover {
  color: var(--primary-color);
  font-weight: 600;
}

.report-analytics .report-analytics-wrap:hover svg g {
  opacity: 1;
}

.report-analytics .report-analytics-wrap:hover svg g path {
  stroke: var(--primary-color);
}

/*************************************
Report and Analytics Ends
**************************************/

/*************************************
Recordings Page Starts
**************************************/

.devices-page .camera-header .available-heading h2 {
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #171717;
}

.site-main-wrapper .main-wrapper .main-page-height .sidebar-theme {
  width: 305px;
  border-right: 5px solid #dadada;
  min-width: 305px;
  position: relative;
}

.site-main-wrapper .main-wrapper .main-page-height .sidebar-theme::before {
  background-color: var(--white-color);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  z-index: -2;
}

.site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme
  .camera-header
  .available-heading
  h3 {
  font-weight: 400;
  font-size: 20px;
  color: #171717;
  margin: 0;
  padding: 10px 0;
  line-height: 1.2;
}

.sidebar-theme .sidebar-wrapper {
  overflow: auto;
  max-height: calc(100vh - 154px);
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-theme .sidebar-wrapper .reset-wrapper {
  padding: 10px 0;
  border-bottom: 1px solid #dadada;
}

.sidebar-theme .sidebar-wrapper .reset-wrapper .btn {
  font-size: 14px;
}

.sidebar-theme .sidebar-wrapper .all-cameras-wrapper .accordion-button {
  background: transparent;
  border-radius: 0;
  padding: 20px 10px;
  color: #171717;
  outline: none !important;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}

.sidebar-theme .sidebar-wrapper .all-cameras-wrapper .accordion-button::after,
.groups-list-wrapper .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015' stroke='%23171717' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
}

.sidebar-theme .sidebar-wrapper .all-cameras-wrapper .accordion-body {
  background: #f3f3f3;
  padding: 15px;
  border-radius: 0px 0px 6px 6px;
}

.sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-button.collapsed {
  border-bottom: 1px solid #dadada;
}

.sidebar-theme .sidebar-wrapper .date-wrapper {
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid #dadada;
  margin: 0 0 20px 0;
}

.date-wrapper h3 {
  font-weight: 300;
  font-size: 32px;
  color: #171717;
}

.sidebar-theme .sidebar-wrapper .date-wrapper p {
  font-weight: 400;
  font-size: 16px;
  color: #686868;
  margin: 0;
}

.sidebar-theme .sidebar-wrapper .time-frame {
  margin: 0 0 20px 0;

  border-radius: 6px;
  position: relative;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker .apply-btn {
  padding-top: 5px;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker .apply-btn .btn {
  max-width: 100%;
}

.sidebar-theme .sidebar-wrapper .time-frame::before {
  background: #f3f3f3;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -2;
}

.sidebar-theme .sidebar-wrapper .time-frame .header {
  padding: 15px;
}

.sidebar-theme .sidebar-wrapper .time-frame .header h4 {
  font-weight: 400;
  font-size: inherit;
  color: #171717;
  margin: 0;
}

.sidebar-theme .sidebar-wrapper .time-frame .header .switch .slider {
  background: #dadada;
}

.sidebar-theme .sidebar-wrapper .all-cameras-wrapper .accordion-body h4 {
  font-weight: 400;
  font-size: 16px;
  color: #3bb44a;
  margin: 0 0 10px 0;
}

.sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-body
  .form-check:not(:last-of-type) {
  margin: 0 0 10px;
}

.sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-body
  .form-check
  .form-check-label {
  color: #484848;
}

.sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-body
  .form-check
  .form-check-label::before {
  top: 3px;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker {
  padding: 0px 15px 15px 15px;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker .form-control {
  background-color: #dadada;
  border-radius: 6px;
  border: none;
  font-weight: 400;
  padding-left: 40px;
  font-size: 14px;
  color: #484848;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1.5V3.75' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.5V3.75' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.625 6.81738H15.375' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.4083 11.8272L11.7533 14.4822C11.6483 14.5872 11.5508 14.7822 11.5283 14.9247L11.3858 15.9372C11.3333 16.3047 11.5883 16.5597 11.9558 16.5072L12.9683 16.3647C13.1108 16.3422 13.3133 16.2447 13.4108 16.1397L16.0658 13.4847C16.5233 13.0272 16.7408 12.4947 16.0658 11.8197C15.3983 11.1522 14.8658 11.3697 14.4083 11.8272Z' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.0254 12.21C14.2504 13.02 14.8804 13.65 15.6904 13.875' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375V9' stroke='%23484848' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.99588 10.2754H9.00262' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.22049 10.2754H6.22723' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.22049 12.5254H6.22723' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 14px center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker .rdtPicker {
  background: #f3f3f3;
  border-radius: 6px;
  width: 100%;
  border: none;
  padding: 15px;
  box-shadow: none;
}

.sidebar-theme .sidebar-wrapper .rdtPicker td {
  color: #949494;
  border-radius: 5px;
  height: 33px;
}

.sidebar-theme .sidebar-wrapper .rdtPicker td.rdtActive,
.sidebar-theme .sidebar-wrapper .rdtPicker td.rdtActive:hover {
  background: #171717;
  color: var(--white-color);
  font-weight: 700;
}

.sidebar-theme .sidebar-wrapper .rdtPicker td.rdtTimeToggle {
  border-color: transparent;
  border-top: 5px solid transparent;
}

.sidebar-theme .sidebar-wrapper .rdtPicker td::before {
  border-bottom: 7px solid var(--black-color);
}

.sidebar-theme .sidebar-wrapper .rdtPicker .dow {
  color: #949494;
}

.sidebar-theme .sidebar-wrapper th.rdtPrev span,
.sidebar-theme .sidebar-wrapper th.rdtNext span {
  color: #949494;
  font-size: 0px;
  line-height: 1;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.sidebar-theme .sidebar-wrapper th.rdtPrev,
.sidebar-theme .sidebar-wrapper th.rdtNext {
  height: auto;
}

.sidebar-theme .sidebar-wrapper th.rdtPrev span {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.499219 7.7998L6.19922 13.3998C6.59922 13.7998 7.19922 13.7998 7.59922 13.3998C7.99922 12.9998 7.99922 12.3998 7.59922 11.9998L2.69922 6.9998L7.59922 1.9998C7.99922 1.5998 7.99922 0.999804 7.59922 0.599804C7.39922 0.399804 7.19922 0.299805 6.89922 0.299805C6.59922 0.299805 6.39922 0.399804 6.19922 0.599804L0.499219 6.1998C0.0992188 6.6998 0.0992188 7.2998 0.499219 7.7998C0.499219 7.6998 0.499219 7.6998 0.499219 7.7998Z' fill='%23949494'/%3E%3C/svg%3E");
}

.sidebar-theme .sidebar-wrapper th.rdtNext span {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.54071 6.28955L1.88071 0.639548C1.78775 0.54582 1.67715 0.471426 1.55529 0.420657C1.43343 0.369888 1.30273 0.34375 1.17071 0.34375C1.0387 0.34375 0.907997 0.369888 0.786138 0.420657C0.664278 0.471426 0.553677 0.54582 0.460714 0.639548C0.274463 0.826911 0.169922 1.08036 0.169922 1.34455C0.169922 1.60873 0.274463 1.86219 0.460714 2.04955L5.41071 7.04955L0.460714 11.9995C0.274463 12.1869 0.169922 12.4404 0.169922 12.7045C0.169922 12.9687 0.274463 13.2222 0.460714 13.4095C0.553329 13.504 0.663774 13.5792 0.785649 13.6307C0.907525 13.6822 1.03841 13.709 1.17071 13.7095C1.30302 13.709 1.4339 13.6822 1.55578 13.6307C1.67765 13.5792 1.7881 13.504 1.88071 13.4095L7.54071 7.75955C7.64222 7.66591 7.72323 7.55225 7.77864 7.42576C7.83404 7.29926 7.86265 7.16265 7.86265 7.02455C7.86265 6.88645 7.83404 6.74984 7.77864 6.62334C7.72323 6.49684 7.64222 6.38319 7.54071 6.28955Z' fill='%23949494'/%3E%3C/svg%3E");
  margin-left: auto;
}

.rdtPicker thead tr:first-of-type th.rdtSwitch {
  color: #484848;
}

.sidebar-theme .sidebar-wrapper .rdtPicker td.rdtMonth {
  padding: 10px 0;
}

.sidebar-theme .sidebar-wrapper .time-frame .date-time-picker .input-wrapper {
  padding-bottom: 15px;
}

.sidebar-theme
  .sidebar-wrapper
  .time-frame
  .date-time-picker
  .input-wrapper.end-date {
  padding-top: 15px;
  border-top: 1px solid #dadada;
}

.sidebar-theme
  .sidebar-wrapper
  .time-frame
  .date-time-picker
  .input-wrapper
  h4 {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #484848;
}

.camera-row .card-camera .card-subhead {
  padding-left: 70px;
  padding-top: 15px;
  padding-right: 12px;
  padding-bottom: 15px;
}

.camera-row .card-camera .card-subhead .icon-wrap svg {
  width: 40px;
  height: 40px;
}

.page-nav .btn {
  width: 100%;
  min-width: 170px;
}

.camera-row .card-camera .card-subhead .text-wrapper {
  padding-right: 15px;
}

.camera-row .card-camera .card-subhead .text-wrapper .title {
  font-weight: 400;
  font-size: 16px;
  color: #484848;
  gap: 6px;
  align-items: center;
}

.camera-row.four-by-three .card-camera .card-subhead {
  padding-left: 15px;
}

.camera-row.four-by-three .card-camera .card-subhead .text-wrapper .title {
  font-size: 14px;
}

.camera-row .card-camera .card-subhead .text-wrapper .title:not(:last-of-type) {
  margin-bottom: 7px;
}

.camera-row .card-camera .card-subhead .text-wrapper .title .icon {
  height: 18px;
}

.camera-row .card-camera .card-subhead .text-wrapper .title .icon svg {
  vertical-align: top;
}

.reset-wrapper .refresh-btn svg {
  margin-right: 2px;
}

.reset-password-form .col-form-label {
  min-width: 155px;
}

/*************************************
Recordings Page Ends
**************************************/

/*************************************
Theme Table Starts
**************************************/

.theme-table td,
.theme-table th {
  border-color: #dadada !important;
  color: #171717;
  padding: 12px 10px;
  vertical-align: middle;
}

.theme-table .form-check-input {
  background: transparent;
  border: 1px solid #949494;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
}

.theme-table .form-check-input:checked {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3e%3cpath  d=%27M4.1,12.7 9,17.6 20.3,6.3%27 fill=%27none%27 strokeWidth =%272px%27 stroke=%27white%27/%3e%3c/svg%3e") !important;
}

.theme-table thead th {
  font-weight: 600 !important;
  font-size: 18px;
}

.theme-table {
  margin-bottom: 35px;
}

.table-responsive.center-table td,
.table-responsive.center-table th {
  text-align: center;
}

.table-responsive.center-table td:first-of-type,
.table-responsive.center-table th:first-of-type {
  text-align: left;
}

.table-responsive.center-table td:last-of-type,
.table-responsive.center-table th:last-of-type {
  text-align: right;
}

.table-responsive.center-table td:last-of-type .item-width,
.table-responsive.center-table th:last-of-type .item-width {
  text-align: center;
}

.table-responsive .item-width {
  width: 160px;
  display: inline-block;
  text-align: center;
}

/*************************************
Theme Table Ends
**************************************/

/*************************************
Camera View Starts
**************************************/

.camera-view .camera-row .card-camera {
  border-top: 1px solid #dadada;
}

.camera-view .camera-row .card-camera .card-header .camera-status {
  padding: 0;
  border: none;
}

.camera-view .camera-row .card-camera .card-header {
  padding: 16px 15px;
}

.camera-view .camera-row .card-camera .card-header .camera-details {
  padding: 0 0 0 15px;
}

.camera-view
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .camera-name {
  font-size: 24px;
}

.camera-view .camera-row .card-camera .card-header svg {
  width: 24px;
  height: 24px;
}

.camera-row .card-camera .card-header .camera-details .btn-notif.ms-1 {
  margin-left: 10px !important;
}

.camera-row .card-camera .card-header .camera-details .details-btn svg path {
  transition: 0.3s;
}

.camera-row .card-camera .card-header .camera-details .details-btn:hover svg g {
  opacity: 1;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .details-btn:hover
  svg
  g
  path {
  fill: var(--primary-color);
}

.camera-view .camera-row .card-camera .card-subhead {
  background: #f3f3f3;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.camera-view
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .mata-details
  .title {
  margin: 0;
  padding: 25px 20px;
}

.camera-view
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .mata-details
  .title:not(:last-of-type) {
  border-right: 1px solid #dadada;
}

.camera-view .camera-row .card-camera .card-subhead .icon-wrap {
  padding-right: 5px;
}

.camera-view .camera-row .card-camera .card-subhead .icon-wrap svg {
  width: auto;
  height: auto;
}

.camera-row .card-camera .card-header .camera-details .dropdown-menu {
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  padding: 0;
  width: 350px;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  .notif-header {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #171717;
  padding: 15px;
  border-bottom: 1px solid rgb(23 23 23 / 50%);
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  .notif-header
  a {
  color: inherit;
  text-decoration: none;
}

.camera-row .card-camera .card-header .camera-details .dropdown-menu ul {
  margin: 0;
  list-style-type: none;
  padding: 0 10px;
  border-bottom: 1px solid rgb(23 23 23 / 50%);
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  ul
  li
  .dropdown-item {
  background: transparent;
  padding: 10px;
  font-size: 14px;
  color: #484848;
  border-bottom: 1px solid rgb(23 23 23 / 50%);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  ul
  li:last-of-type
  .dropdown-item {
  border-bottom: none;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  .notification-footer {
  padding: 20px;
  text-align: center;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  .notification-footer
  .btn {
  padding: 13px 15px;
  max-width: 230px;
}

/*************************************
Camera View Ends
**************************************/

/*************************************
Recodging Details View Starts
**************************************/
.site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme.sidebar-details {
  background: #f3f3f3;
}

.sidebar-theme.sidebar-details .sidebar-wrapper {
  padding: 0;
}

.sidebar-theme.sidebar-details .sidebar-wrapper .camera-list {
  padding: 0;
  margin: 0;
}

.sidebar-theme.sidebar-details .sidebar-wrapper .camera-list li {
  padding: 15px 27px;
  border-color: var(--white-color);
  border-width: 1px 0;
  border-style: solid;
  font-weight: 400;
  font-size: 16px;
  color: #949494;
}

.sidebar-theme.sidebar-details .sidebar-wrapper .camera-list li a {
  text-decoration: none;
  color: inherit;
}

.sidebar-theme.sidebar-details .sidebar-wrapper .camera-list li .camera-id {
  color: #171717;
  width: 100px;
}

.sidebar-theme.sidebar-details .sidebar-wrapper .camera-list li.active {
  border-color: #3bb44a;
  background: var(--white-color);
  color: #000000;
}

.sidebar-theme.sidebar-details .camera-header .dropdown-wrap {
  color: #171717;
}

.sidebar-theme.sidebar-details .camera-header .dropdown-wrap .dropdown .btn {
  display: flex;
  align-items: flex-end;
  background: transparent;
}

.sidebar-theme.sidebar-details
  .camera-header
  .dropdown-wrap
  .dropdown
  .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015' stroke='%23171717' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  border: none;
  width: 24px;
  background-size: 20px;
  height: 24px;
  background-position: center;
}

.sidebar-theme.sidebar-details
  .camera-header
  .dropdown-wrap
  .dropdown
  .dropdown-menu {
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  border-color: var(--white-color);
}

.devices-page .camera-header .button-wrap .btn-setting {
  border: 1px solid var(--black-color) !important;
  border-radius: 24px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  justify-content: center;
}

.devices-page .camera-header .button-wrap .btn-delete,
.create-user-wrapper .form-wrapper .button-wrap .btn-delete {
  border: 1px solid var(--danger-color) !important;
  border-radius: 24px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 0;
  justify-content: center;
}

.create-user-wrapper .form-wrapper .button-wrap .btn-delete {
  width: auto;
  padding: 10px 15px;
  width: 200px;
  max-width: 200px;
}

.devices-page .camera-header.details-header .dropdown .btnn {
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  outline: none;
}

.devices-page .camera-header.details-header .dropdown .btnn::after {
  border-top: 6px solid #949494;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
}

.devices-page .camera-header.details-header .available-heading {
  padding-left: 40px;
}

.devices-page .camera-header.details-header .available-heading h2 .edit-btn,
.subscription-box .heading .edit-btn {
  background: #dfdfdf;
  border: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-left: 10px;
}

.devices-page .camera-header.details-header .dropdown .btnn svg path {
  stroke: var(--white-color);
}

.details-view .header-tabs {
  border: none;
  background: #ffffff;
}

.details-view .header-tabs li.nav-item {
  width: 33.33%;
}

.details-view .header-tabs li.nav-item .nav-link {
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #373737;
  padding: 18px 15px;
  border: none;
  border-radius: 0;
  position: relative;
  transition: 0.3s;
  background-color: transparent;
}

.details-view .header-tabs li.nav-item .nav-link::before {
  content: "";
  background: #3bb44a;
  height: 3px;
  width: 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}

.details-view .header-tabs li.nav-item .nav-link.active::before,
.details-view .header-tabs li.nav-item .nav-link:hover::before {
  width: 100%;
}

.details-view .header-tabs li.nav-item .nav-link.active,
.details-view .header-tabs li.nav-item .nav-link:hover {
  font-weight: 700;
}

.details-view .add-new-device-page {
  padding: 0;
  /* height: calc(100vh - 201px); */
}

.details-view .add-new-device-page .theme-form-wrapper {
  padding: 50px;
}

.details-view .add-new-device-page .theme-form-wrapper .form {
  max-width: 630px;
  margin: auto;
}

.add-new-device-page .theme-form-wrapper .btn.w-125px {
  max-width: 125px;
}

.add-new-device-page .theme-form-wrapper .edit-btn {
  border-color: var(--primary-color);
}

.add-new-device-page .theme-form-wrapper .edit-btn:hover svg path {
  stroke: #fff;
}

.add-new-device-page .theme-form-wrapper .edit-btn svg path {
  transition: 0.3s;
}

.add-new-device-page .theme-form-wrapper .btn.w-125px {
  max-width: 125px;
}

.add-new-device-page .theme-form-wrapper .edit-btn {
  border-color: var(--primary-color);
}

.add-new-device-page .theme-form-wrapper .edit-btn:hover svg path {
  stroke: #fff;
}

.add-new-device-page .theme-form-wrapper .edit-btn svg path {
  transition: 0.3s;
}

/*************************************
Recodging Details View Ends
**************************************/

/*************************************
Live View Starts
**************************************/

.details-view .live-vew-page .card-camera-body {
  height: calc(100vh - 201px);
  position: relative;
}

.details-view .live-vew-page .card-camera-body .full-screen-btn {
  position: absolute;
  right: 20px;
  top: 30px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 10;
}

.camera-list-wrapper .status-live {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  z-index: 1;
  padding: 10px 15px 10px 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 5rem;
  justify-content: center;
}

.details-view .live-vew-page .card-camera-body .status-live {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 30px;
  padding: 10px 15px 10px 10px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
}

.details-view .live-vew-page .card-camera-body img {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: contain;
}

.details-view .live-vew-page .card-camera-body::before {
  content: "";
  /* background: #000; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  /* opacity: .5; */
}

.details-view .live-vew-page .card-camera-body .status-live .status,
.camera-list-wrapper .status-live .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.details-view .live-vew-page .card-camera-body .status-live .status.active,
.camera-list-wrapper .status-live .status.active {
  background: var(--primary-color);
}

.details-view .live-vew-page .card-camera-body .status-live .status.inactive,
.camera-list-wrapper .status-live .status.inactive {
  background: var(--danger-color);
}

/*************************************
Live View Ends
**************************************/

/*************************************
Notifications View Starts
**************************************/
.notification-view-page .notification-list.add-new-device-page {
  border-right: 5px solid #dadada;
}

.notification-view-page {
  background: #fff;
}

.notification-view-page .notification-list.add-new-device-page .day {
  background: #e4e4e4;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  padding: 15px;
  color: #676767;
}

.notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper {
  border-color: var(--white-color);
  border-width: 1px 0;
  border-style: solid;
  background: #f3f3f3;
  padding: 15px;
  font-weight: 400;
  font-size: 16px;
  color: #676767;
  border-left: 3px solid var(--danger-color);
  transition: 0.3s;
  cursor: pointer;
}

.notification-view-page .video-wrap {
  height: 100%;
  background: #f1f1f1;
}

.notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper
  .timestamp {
  margin-right: 40px;
  white-space: nowrap;
}

.notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper
  .text {
  padding: 0 15px;
}

.notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper.active,
.notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper:hover {
  background: #ffffff;
  border-color: var(--primary-color);
}

.notification-view-page .video-wrap .video-section video {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.notification-view-page .video-wrap .text-wrap {
  padding: 0 20px;
}

.notification-view-page .video-wrap .text-wrap .text-inner {
  padding: 20px 0px;
  border-bottom: 1px solid #dadada;
  margin-bottom: 25px;
}

.notification-view-page .video-wrap .text-wrap .text-inner .title {
  gap: 6px;
  align-items: center;
  color: #484848;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.notification-view-page .video-wrap .text-wrap .text-inner .title .icon {
  height: 18px;
}

.notification-view-page .video-wrap .text-wrap .text-inner .title .icon svg {
  vertical-align: top;
}

.notification-view-page .video-wrap .download-button .download-btn {
  border: none;
  display: inline-flex;
  align-items: center;
  color: #949494;
  gap: 10px;
  background: transparent;
}

/*************************************
Notifications View Ends
**************************************/

/*************************************
Delete Modal Starts
**************************************/

.modal-delete .modal-content {
  border: none;
  background: transparent;
  border-radius: 3px;
}

.modal-delete .modal-content::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  overflow: hidden;
  border-radius: 3px;
  z-index: -2;
}

.modal-delete .modal-content .modal-body {
  padding: 25px 30px;
}

.modal-delete .modal-dialog {
  max-width: 660px;
}

.modal-delete .text-wrap {
  margin: 0 0 30px 0;
}

.modal-delete .text-wrap h4 {
  font-weight: 600;
  font-size: 24px;
  color: var(--black-color);
  margin-bottom: 25px;
}

.modal-delete .text-wrap .note {
  background: #fee8e8;
  border-radius: 1px;
  font-weight: 600;
  font-size: 14px;
  color: var(--danger-color);
  padding: 25px 30px;
  border-left: 8px solid var(--danger-color);
}

.modal-delete .button-wrap .btn-bordered {
  border: 1px solid #dadada;
  color: #484848;
}

.modal-delete .button-wrap .btn + .btn {
  margin-left: 20px;
}

.modal-delete .button-wrap .btn {
  min-width: 180px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 27px;
}

.modal-delete .text-wrap .note .heading {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-delete .text-wrap .note p {
  margin: 0;
}

.modal-delete .button-wrap .btn-bordered:hover {
  background: #dadada;
}

.dark-theme .modal-delete .modal-content::before {
  background: #121212;
}

.modal-backdrop {
  background: #181818;
  opacity: 0.75;
}

.dark-theme .modal-delete .text-wrap h4 {
  color: var(--white-color);
}

.dark-theme .modal-delete .text-wrap .note {
  background: #222222;
  color: var(--white-color);
}

.dark-theme .modal-delete .button-wrap .btn-bordered {
  border: 1px solid rgba(255, 255, 255, 0.11);
  color: var(--white-color);
}

.dark-theme .modal-delete .button-wrap .btn-bordered:hover {
  color: #484848;
}

.dark-theme .modal-delete .text-wrap .note .heading svg path,
.dark-theme .modal-delete .text-wrap .note .heading svg circle {
  stroke: #fff;
}

/*************************************
Delete Modal Ends
**************************************/

/*************************************
Message Modal Starts
**************************************/

/************mesage Modal ***********/

.messageModal .modal-dialog {
  max-width: 650px;
}

.messageModal .modal-dialog .modal-content {
  border: none;
  box-shadow: none;
  border-radius: 3px;
}

.messageModal .modal-dialog .modal-content .btn-close {
  position: absolute;
  right: 12px;
  opacity: 1;
  color: #181818;
  top: 14px;
}

.messageModal .modal-dialog .modal-content .modal-body {
  padding: 50px;
  position: relative;
}

.messageModal .modal-dialog .modal-content .modal-text .icon-wrap {
  margin: 0 auto 20px;
  width: 154px;
  height: 154px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.messageModal .modal-dialog .modal-content .modal-text.success .icon-wrap {
  border: 6px solid rgba(62, 175, 63, 0.34);
}

.messageModal .modal-dialog .modal-content .modal-text.failure .icon-wrap {
  border: 6px solid rgba(255, 96, 88, 0.63);
}

.messageModal .modal-dialog .modal-content .modal-text h2 {
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 20px;
}

.messageModal .modal-dialog .modal-content .modal-text p {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  opacity: 0.5;
  margin: 0;
}

.messageModal .modal-dialog .modal-content .modal-text.success h2 {
  color: #3eaf3f;
}

.messageModal .modal-dialog .modal-content .modal-text.failure h2 {
  color: var(--danger-color);
}

.dark-theme .messageModal .modal-dialog .modal-content {
  background: #121212;
}
.camera-storage-fullscreen-modal .modal-dialog .modal-content {
  background: #121212;
}

.camera-storage-fullscreen-modal .modal-dialog .modal-content .modal-header .btn-close {
  background-color: white;
}
.dark-theme .messageModal .modal-dialog .modal-content .modal-text p {
  color: #fff;
}

.dark-theme .messageModal .modal-dialog .modal-content .btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}

/*************************************
Message Modal Ends
**************************************/

/*************************************
Add Modal Starts
**************************************/
.add-modal .modal-dialog {
  max-width: 660px;
}

.add-modal .modal-dialog .modal-content {
  background: transparent;
  border: none;
}

.add-modal .modal-dialog .modal-content::before {
  content: "";
  width: 100%;
  background: #f3f3f3;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.add-modal .modal-dialog .modal-content .modal-header {
  background: #ffffff;
  border: none;
  padding: 12px 10px;
  justify-content: center;
  position: relative;
  border-radius: 0;
}

.add-modal .modal-dialog .modal-content .modal-header .btn-close,
.full-screen-modal .modal__wrapper .btn-close {
  margin: 0;
  padding: 0;
  opacity: 1;
  background: transparent !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ff6058;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  box-shadow: none;
}

.add-modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 24px;
}

.add-modal .modal-dialog .modal-content .modal-body {
  padding: 40px;
}

.add-modal
  .modal-dialog
  .modal-content
  .modal-body
  .theme-form-wrapper
  .flex-grow-1 {
  padding-left: 25px;
}

.add-modal
  .modal-dialog
  .modal-content
  .modal-body
  .theme-form-wrapper
  .select-address {
  accent-color: #3bb44a;
}

.add-modal .modal-dialog .modal-content .modal-body .button-wrap {
  padding-top: 15px;
}

.add-modal .modal-dialog .modal-content .modal-body .button-wrap .btn {
  width: 100%;
  max-width: 300px;
}

.dark-theme .add-modal .modal-dialog .modal-content::before {
  background: #272727;
}

.dark-theme .add-modal .modal-dialog .modal-content .modal-header {
  background: #000;
}

.dark-theme
  .add-modal
  .modal-dialog
  .modal-content
  .modal-header
  .btn-close
  .full-screen-modal
  .modal__wrapper
  .btn-close {
  background-color: #fff !important;
  border-color: #fff;
}

/*************************************
Add Modal Ends
**************************************/

/*************************************
Notes Screen Starts
**************************************/
.notes-view-page {
  padding: 25px !important;
}

.notes-view-page .addCard {
  text-align: center;
  border: 2px solid #dadada;
  border-radius: 10px;
  margin-bottom: 30px;
}

.notes-view-page .addCard .note .icon-wrap {
  margin: 0 0 25px 0;
}

.notes-view-page .addCard .note .title {
  color: #484848;
  font-size: 24px;
}

.notes-view-page .card {
  margin-bottom: 30px;
  background: #fcfcfc;
  border: none;
  border-radius: 0;
}

.notes-view-page .card .card-header {
  padding-right: 5px;
  border-bottom: 1px solid #dadada;
  background: transparent;
}

.notes-view-page .card .card-header h3 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  color: #171717;
}

.notes-view-page .card .card-body p {
  color: #949494;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.notes-view-page .card .card-header .deleteButton {
  border: none;
  background: transparent;
  padding: 0;
}

.notes-view-page .addCard .note.theme-form-wrapper {
  padding: 25px;
}

.notes-view-page .addCard .note.theme-form-wrapper .add-card-empty {
  padding: 20px;
}

.notes-view-page .addCard .note.theme-form-wrapper .description {
  font-size: 16px;
}

.notes-view-page .addCard .note.theme-form-wrapper .title-field {
  font-size: 24px;
}

/*************************************
Notes Screen Ends
**************************************/

/*************************************
Groups Screen Starts
**************************************/

.sidebar-theme .camera-switch-wrap .check-switch {
  border: 1px solid #dadada;
  border-radius: 24px;
  height: 34px;
}

.sidebar-theme .camera-switch-wrap h3 {
  padding: 5px 0 !important;
  font-weight: 400;
  font-size: 24px !important;
}

.sidebar-theme .camera-switch-wrap .check-switch .form-check {
  margin: 0;
  min-height: inherit;
  display: inline-flex;
}

.sidebar-theme .camera-switch-wrap .check-switch .form-check .form-check-label {
  border-radius: 24px;
  padding: 7px 5px;
  width: 72px;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #686868;
  justify-content: center;
}

.sidebar-theme
  .camera-switch-wrap
  .check-switch
  .form-check
  .form-check-label::before {
  display: none;
}

.sidebar-theme
  .camera-switch-wrap
  .check-switch
  .form-check
  input:checked
  + .form-check-label.active {
  background: var(--primary-color);
  color: #fff;
}

.sidebar-theme
  .camera-switch-wrap
  .check-switch
  .form-check
  input:checked
  + .form-check-label.inactive {
  background: var(--danger-color);
  color: #fff;
}

.devices-page .camera-header .available-heading .add-btn {
  border: none;
  background: transparent;
  outline: none;
}

.site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme.groups-sidebar {
  width: 420px;
}

.sidebar-theme.groups-sidebar .cameras-list {
  padding: 15px 0;
}

.sidebar-theme.groups-sidebar .camera-wrap {
  border: 1px solid #dadada;
  border-radius: 6px;
  padding: 15px;
  align-items: center;
}

.sidebar-theme.groups-sidebar .camera-wrap .button-wrap {
  vertical-align: top;
}

.sidebar-theme.groups-sidebar .camera-wrap .button-wrap .setting-icon {
  padding: 0 2px;
  border: none;
  background: transparent;
  vertical-align: top;
}

.sidebar-theme.groups-sidebar .camera-wrap .button-wrap .setting-icon svg path {
  fill: #dadada;
}

.sidebar-theme.groups-sidebar .camera-wrap .form-check {
  margin: 0;
}

.sidebar-theme .sidebar-wrapper .reset-wrapper .dropdown .btn {
  border-radius: 0;
  padding: 5px 0px;
  border: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .reset-wrapper
  .dropdown
  .btn
  svg
  path {
  stroke: var(--white-color);
}

.sidebar-theme.groups-sidebar .camera-wrap:not(:last-of-type) {
  margin-bottom: 10px;
}

.sidebar-theme.groups-sidebar .camera-wrap .form-check .form-check-label {
  padding-left: 35px;
  gap: 5px;
  opacity: 1;
  color: #484848;
}

.sidebar-theme.groups-sidebar
  .camera-wrap
  .form-check
  .form-check-label::before {
  border-color: #3bb44a;
}

.sidebar-theme.groups-sidebar
  .camera-wrap
  .form-check
  input:disabled
  + .form-check-label::before {
  border-color: #c1c7cf;
}

.groups-list-wrapper .accordion-item {
  margin-bottom: 10px;
  border: none;
  background: transparent;
}

.groups-list-wrapper .accordion-item .accordion-button {
  color: #000000;
  outline: none;
  box-shadow: none;
  background: #ffffff;
  border-radius: 6px !important;
  border: 1px solid #fff;
}

.groups-list-wrapper .accordion-item .accordion-body {
  padding: 10px 0 0 0;
}

.groups-list-wrapper .accordion-item .accordion-button:not(.collapsed) {
  background: #dadada;
  border-color: #dadada;
}

.groups-list-wrapper .accordion-item .camera-wrap .left-side {
  background: #e3e3e3;
  border-radius: 6px;
  padding: 15px;
  margin-right: 30px;
}

.left-sides {
  background: #504e4e;
  border-radius: 6px;
  padding: 10px;
  margin-right: 30px;
}

.groups-list-wrapper .accordion-item .camera-wrap .left-side .camera-title {
  color: #171717;
}

.groups-list-wrapper .accordion-item .camera-wrap .button-wrap .setting-icon {
  padding: 0 2px;
  border: none;
  background: transparent;
  vertical-align: top;
}

.groups-list-wrapper
  .accordion-item
  .camera-wrap
  .button-wrap
  .setting-icon
  path {
  fill: #c8c8c8;
}

.groups-list-wrapper
  .accordion-item
  .camera-wrap
  .delete-button-wrap
  .setting-icon {
  background: #e6e6e6;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none;
}

.groups-list-wrapper .accordion-item .camera-wrap:not(:last-of-type) {
  margin-bottom: 10px;
}

.sidebar-theme.groups-sidebar .camera-wrap .button-wrap .setting-icon svg g {
  opacity: 1;
}

.sidebar-theme.groups-sidebar .camera-wrap .button-wrap .setting-icon svg path {
  fill: #dadada;
}

.groups-list-wrapper .accordion-item .button-wrapper .btn {
  /* width: 100%; */
  /* max-width: 100%; */
  max-width: 200px;
}

.groups-list-wrapper .accordion-item .button-wrapper .save-button {
  flex-grow: 1 !important;
  max-width: 160px;
}

.groups-list-wrapper .accordion-item .button-wrapper .cancel-button {
  padding-left: 10px;
}

.groups-list-wrapper .accordion-item .button-wrapper .cancel-button .btn {
  min-width: 160px;
}

.groups-list-wrapper .accordion-item.selected {
  cursor: pointer;
}

.groups-list-wrapper .accordion-item.selected .accordion-button {
  /* pointer-events: none; */
  border-color: #ff6058 !important;
}

.groups-list-wrapper .accordion-item.selected .accordion-button::after,
.dark-theme
  .groups-list-wrapper
  .accordion-item.selected
  .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22656 8.77323L8.77323 1.22656' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.77323 8.77323L1.22656 1.22656' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-size: 12px;
  background-position: center;
}

/*************************************
Groups Screen Ends
**************************************/

/*************************************
Reseller Module Screen Starts
**************************************/
.reseller-module .fixed-footer,
.reseller-module .site-header .logo-wrap .camera-dropdown,
.reseller-module .left-navigation-menu,
.reseller-module .header.site-header .search-profile-wrap .search-wrap {
  display: none !important;
}

.reseller-module .header.site-header {
  /* background: transparent; */
}

.reseller-module
  .header.site-header
  .search-profile-wrap
  .profile-wrap
  .profile-btn {
  padding: 0;
  border: none;
}

.reseller-module .resellter-sidebar .logo-wrap {
  margin-bottom: 60px;
}

.reseller-module .resellter-sidebar .menu-wrapper ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.reseller-module .resellter-sidebar .menu-wrapper ul li a {
  font-weight: 400;
  font-size: 18px;
  color: #272727;
  opacity: 0.8;
  text-decoration: none;
  padding: 15px 0;
  transition: 0.3s;
  width: 100%;
  display: inline-block;
}

.reseller-module .resellter-sidebar .menu-wrapper ul li a.active,
.reseller-module .resellter-sidebar .menu-wrapper ul li a:hover {
  color: #ffcc33;
  font-weight: 700;
}

.reseller-module .reseller-inner-header {
  background: #fff;
  border-bottom: 1px solid #dadada;
  padding: 20px 40px;
}

.reseller-module .resellter-sidebar {
  padding: 23px;
  width: 380px;
  background: #fff;
  border-right: 1px solid #dadada;
}

.reseller-module .form-reseller {
  padding: 25px 25px;
  max-width: 1100px;
  position: relative;
}

.reseller-module .form-reseller::before {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-block;
  content: "";
  z-index: -3;
  background: #fff;
  left: 0;
  top: 0;
}

.reseller-module .form-reseller .form-control {
  background-color: #f1f1f1;
}

.reseller-module .form-reseller .form-control:focus {
  background-color: transparent !important;
}

.reseller-module .form-reseller .col-form-label {
  margin: 0 0 5px 0;
  padding: 0;
}

.reseller-module .main-wrapper .camera-list-wrapper {
  max-height: calc(100vh - 85px);
}

.content-page .section-title h2 small {
  font-size: 60%;
  font-weight: 500;
}

.reseller-module .form-reseller .btn.btn-rounded {
  max-width: inherit;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.dark-theme .reseller-module .form-reseller::before {
  background: #202020;
}

.dark-theme .reseller-module .form-reseller .form-control {
  background-color: rgba(255, 255, 255, 0.11);
}

.dark-theme .reseller-module .resellter-sidebar .menu-wrapper ul li a {
  color: #ffffff;
}

.dark-theme .reseller-module .resellter-sidebar {
  border-right: 1px solid #fff;
}

.dark-theme .reseller-module .resellter-sidebar {
  background: #010101;
  border-right: 1px solid #383838;
}

.dark-theme .reseller-module .reseller-inner-header {
  background: #000000;
  border-bottom: 1px solid #383838;
}

/*************************************
Reseller Module Screen Ends
**************************************/

/*************************************
Dark Theme Starts
**************************************/

body.dark-theme {
  background: #272727;
}

.dark-theme .btn-primary:disabled {
  background: #171717 !important;
}

.dark-theme .btn-primary:disabled::before,
.dark-theme .btn-primary:disabled::after {
  display: none;
}

.dark-theme .group-checkbox .group-checkbox-wrap .form-check .add-btn svg path {
  stroke: var(--white-color);
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #949494;
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control:-ms-input-placeholder {
  color: #949494;
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .form-control::placeholder {
  color: #949494;
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .input-group
  .input-group-text
  .btn
  svg
  path {
  stroke: var(--white-color);
}

.dark-theme .group-checkbox .group-checkbox-wrap .form-check-label {
  background: #171717;
  color: var(--white-color);
  border-color: #171717;
}

.dark-theme
  .group-checkbox
  .group-checkbox-wrap
  input:checked
  + .form-check-label {
  background: var(--black-color);
  border: 1px solid var(--black-color);
}

.dark-theme,
.dark-theme .camera-header .dropdown .dropdown-menu,
.dark-theme .site-header .logo-wrap .camera-dropdown,
.content-page .section-title p,
.dark-theme .site-header .add-device-wrap .add-device,
.dark-theme .site-header .add-device-wrap .refresh,
.dark-theme .site-header .search-profile-wrap .search-wrap .form-control {
  color: var(--white-color);
}

.dark-theme .site-header .search-profile-wrap .search-wrap .form-control {
  background: #171717;
}

.dark-theme .refresh-btn {
  color: #979797;
}

.dark-theme .refresh-btn path {
  fill: #979797;
}

.dark-theme .camera-row .card-camera .card-header,
.dark-theme .camera-row .card-camera,
.dark-theme .devices-page .camera-header .dropdown .dropdown-menu,
.dark-theme .site-map-view .camera-header .dropdown .dropdown-menu,
.dark-theme .site-header,
.dark-theme .table-dropdown.dropdown .dropdown-menu,
.dark-theme .ques-options .dropdown-menu {
  background: var(--black-color);
}

.dark-theme .site-header .add-device-wrap .refresh-btn {
  color: #fff;
}

.dark-theme .site-header .add-device-wrap .refresh svg path {
  fill: var(--white-color);
}

.dark-theme
  .site-header
  .display-wrap
  .display-inputs
  .form-checkbox
  label
  svg
  path,
.dark-theme .devices-page .camera-header .dropdown .btnn path {
  stroke: var(--white-color);
}

.dark-theme .site-header .logo-wrap,
.dark-theme .site-header .search-profile-wrap .profile-btn {
  border-color: #272727;
}

.dark-theme .devices-page .camera-header,
.dark-theme .site-map-view .camera-header {
  border-color: #171717;
}

.dark-theme .devices-page .camera-header::before,
.dark-theme .site-map-view .camera-header::before {
  background: #171717;
}

.dark-theme .devices-page .camera-header .filter-list li button {
  color: #aeaeae;
}

.dark-theme .existing-user .subscription-group .subscription-box .heading p {
  color: #aeaeae;
}

.dark-theme
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .details-btn
  svg
  g,
.camera-row .card-camera .card-header .camera-details button svg g {
  opacity: 1;
}

.dark-theme
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .details-btn
  svg
  path,
.camera-row .card-camera .card-header .camera-details button svg path {
  fill: #aeaeae;
}

.dark-theme .settings-header ul li a {
  color: #aeaeae;
}

.dark-theme .settings-header ul li a svg path {
  stroke: #aeaeae;
}

.dark-theme .devices-page .camera-header .filter-list li button.active {
  color: var(--primary-color);
}

.dark-theme .devices-page .camera-header .dropdown .btnn.show,
.dark-theme .site-map-view .camera-header .dropdown .btnn.show {
  background: transparent !important;
}

.dark-theme .devices-page .camera-header .dropdown .dropdown-menu,
.dark-theme .site-map-view .camera-header .dropdown .dropdown-menu,
.dark-theme .table-dropdown.dropdown .dropdown-menu,
.dark-theme .ques-options .dropdown-menu {
  border-color: #383838;
}

.dark-theme .camera-row .card-camera .card-header .camera-status {
  border-color: #272727;
}

.dark-theme .camera-row .card-camera .card-header .camera-details .camera-name {
  color: #ffffff;
}

.dark-theme .devices-page .add-device {
  border: 2px solid #171717;
  background: transparent;
  color: #484848;
}

.dark-theme .theme-form-wrapper .col-form-label {
  color: var(--white-color);
}

.dark-theme .theme-form-wrapper hr {
  background: #171717;
}

.dark-theme .theme-form-wrapper .form-control {
  background-color: rgba(255, 255, 255, 0.11);
  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.01);
}

.dark-theme .theme-form-wrapper .form-control:focus {
  background-color: transparent;
  border: 1px solid #3bb44a;
}

.dark-theme .theme-form-wrapper .sync-device-checkbox .form-check-label {
  background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7474 12.0758L15.421 11.697L15.7475 12.0757C19.1551 9.13723 23.5012 7.5143 28.0008 7.5C37.168 7.50039 44.7579 14.3144 45.5832 23.0734L45.6202 23.4663L46.011 23.5216C51.3521 24.2764 55.5 28.7442 55.5 34.2055C55.5 40.2002 50.4962 45 44.4045 45H13.2335C6.23893 45 0.5 39.4899 0.5 32.613C0.5 26.692 4.75298 21.7735 10.4048 20.5257L10.735 20.4528L10.7903 20.1192C11.2667 17.2437 13.1294 14.3315 15.7474 12.0758Z' stroke='%23171717'/%3E%3Cpath d='M23 35L33 25' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M33 35L23 25' stroke='%23FF6058' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option {
  border-color: var(--black-color) !important;
}

.dark-theme .left-navigation-menu {
  background: var(--black-color);
}

.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option {
  border-color: var(--black-color);
}

.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-text {
  color: var(--white-color);
}

.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover,
.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option.side-navigation-panel-select-option-selected {
  border-color: rgb(59, 180, 74) !important;
  background: #171717 !important;
}

.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option
  .icon {
  opacity: 0.5;
}

.dark-theme .side-navigation-panel-select-option-selected .icon,
.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon {
  opacity: 1 !important;
}

.dark-theme .side-navigation-panel-select-option-selected .icon.camera,
.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.camera {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.3325 3.5H13.6675C7.2975 3.5 3.5 7.2975 3.5 13.6675V28.315C3.5 34.7025 7.2975 38.5 13.6675 38.5H28.315C34.685 38.5 38.4825 34.7025 38.4825 28.3325V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5ZM25.655 24.0275L23.415 25.3225L21.175 26.6175C18.2875 28.28 15.925 26.915 15.925 23.59V21V18.41C15.925 15.0675 18.2875 13.72 21.175 15.3825L23.415 16.6775L25.655 17.9725C28.5425 19.635 28.5425 22.365 25.655 24.0275Z' fill='white'/%3E%3C/svg%3E");
}

.dark-theme .side-navigation-panel-select-option-selected .icon.recordings,
.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.recordings {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.0125 10.7975C36.295 10.4125 34.79 10.01 32.7425 11.445L30.17 13.265C29.9775 7.8225 27.615 5.6875 21.875 5.6875H11.375C5.39 5.6875 3.0625 8.015 3.0625 14V28C3.0625 32.025 5.25 36.3125 11.375 36.3125H21.875C27.615 36.3125 29.9775 34.1775 30.17 28.735L32.7425 30.555C33.8275 31.325 34.7725 31.57 35.525 31.57C36.1725 31.57 36.68 31.3775 37.0125 31.2025C37.73 30.835 38.9375 29.8375 38.9375 27.335V14.665C38.9375 12.1625 37.73 11.165 37.0125 10.7975ZM19.25 19.915C17.4475 19.915 15.96 18.445 15.96 16.625C15.96 14.805 17.4475 13.335 19.25 13.335C21.0525 13.335 22.54 14.805 22.54 16.625C22.54 18.445 21.0525 19.915 19.25 19.915Z' fill='white'/%3E%3C/svg%3E");
}

.dark-theme .side-navigation-panel-select-option-selected .icon.groups,
.dark-theme
  .left-navigation-menu
  .side-navigation-panel
  .side-navigation-panel-select-option:hover
  .icon.groups {
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.3325 3.5H22.3125V14V15.3125V24.0625H38.5V15.3125V14V13.6675C38.5 7.2975 34.7025 3.5 28.3325 3.5Z' fill='white'/%3E%3Cpath d='M3.5 17.9375V26.6875V27.5625V28.3325C3.5 34.7025 7.2975 38.5 13.6675 38.5H19.6875V27.5625V26.6875V17.9375H3.5Z' fill='white'/%3E%3Cpath d='M19.6875 3.5V15.3125H3.5V13.6675C3.5 7.2975 7.2975 3.5 13.6675 3.5H19.6875Z' fill='white'/%3E%3Cpath d='M38.5 26.6875V28.3325C38.5 34.7025 34.7025 38.5 28.3325 38.5H22.3125V26.6875H38.5Z' fill='white'/%3E%3C/svg%3E");
}

.dark-theme .right-navigation-menu {
  background: var(--black-color);
}

.dark-theme .right-navigation-menu .account-wrap:hover .title {
  color: var(--white-color);
}

.dark-theme .right-navigation-menu .account-wrap {
  border-bottom: 1px solid #373737;
  cursor: pointer;
}

.dark-theme .right-navigation-menu .account-wrap p {
  color: var(--white-color);
}

.dark-theme .account-wrapper ul li {
  background: #272727;
}

.dark-theme .right-navigation-menu .account-wrap:hover .setting-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM7.67 5.5C7.67 5.09 8.01 4.75 8.42 4.75C8.83 4.75 9.17 5.09 9.17 5.5V9.4C9.17 9.81 8.83 10.15 8.42 10.15C8.01 10.15 7.67 9.81 7.67 9.4V5.5ZM9.52282 16.4313C9.31938 16.5216 9.17 16.7132 9.17 16.9358V18.5C9.17 18.91 8.83 19.25 8.42 19.25C8.01 19.25 7.67 18.91 7.67 18.5V16.9358C7.67 16.7132 7.5206 16.5216 7.31723 16.4311C6.36275 16.0064 5.7 15.058 5.7 13.95C5.7 12.45 6.92 11.22 8.42 11.22C9.92 11.22 11.15 12.44 11.15 13.95C11.15 15.0582 10.4791 16.0066 9.52282 16.4313ZM16.33 18.5C16.33 18.91 15.99 19.25 15.58 19.25C15.17 19.25 14.83 18.91 14.83 18.5V14.6C14.83 14.19 15.17 13.85 15.58 13.85C15.99 13.85 16.33 14.19 16.33 14.6V18.5ZM15.58 12.77C14.08 12.77 12.85 11.55 12.85 10.04C12.85 8.93185 13.5209 7.98342 14.4772 7.55873C14.6806 7.46839 14.83 7.27681 14.83 7.05421V5.5C14.83 5.09 15.17 4.75 15.58 4.75C15.99 4.75 16.33 5.09 16.33 5.5V7.06421C16.33 7.28681 16.4794 7.47835 16.6828 7.56885C17.6372 7.9936 18.3 8.94195 18.3 10.05C18.3 11.55 17.08 12.77 15.58 12.77Z' fill='white'/%3E%3C/svg%3E");
}

.dark-theme .right-navigation-menu .account-wrap:hover .help-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z' fill='white'/%3E%3Cpath d='M12 16L12 11' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.0054 8L11.9964 8' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dark-theme .right-navigation-menu .account-wrap:hover .report-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.97 7.21C21.69 4.48 19.52 2.31 16.79 2.03C16.59 2.01 16.39 2 16.19 2H7.81C7.61 2 7.41 2.01 7.21 2.03C4.48 2.31 2.31 4.48 2.03 7.21C2.01 7.41 2 7.61 2 7.81V16.19C2 16.39 2.01 16.59 2.03 16.79C2.31 19.52 4.48 21.69 7.21 21.97C7.41 21.99 7.61 22 7.81 22H14C14.55 22 15 21.55 15 21V18.03C15 16.36 16.36 15 18.03 15H21C21.55 15 22 14.55 22 14V7.81C22 7.61 21.99 7.41 21.97 7.21ZM7.75 13.5C7.75 13.91 7.41 14.25 7 14.25C6.59 14.25 6.25 13.91 6.25 13.5V10.5C6.25 10.09 6.59 9.75 7 9.75C7.41 9.75 7.75 10.09 7.75 10.5V13.5ZM12.75 13.5C12.75 13.91 12.41 14.25 12 14.25C11.59 14.25 11.25 13.91 11.25 13.5V10.5C11.25 10.09 11.59 9.75 12 9.75C12.41 9.75 12.75 10.09 12.75 10.5V13.5Z' fill='white'/%3E%3Cpath d='M20.97 16H18.03C16.76 16 16 16.76 16 18.03V20.97C16 22.24 16.76 23 18.03 23H20.97C22.24 23 23 22.24 23 20.97V18.03C23 16.76 22.24 16 20.97 16ZM21.47 20.56C21.72 20.81 21.72 21.22 21.47 21.47C21.34 21.59 21.17 21.65 21.01 21.65C20.85 21.65 20.68 21.59 20.55 21.47L19.49 20.41L18.45 21.47C18.32 21.59 18.16 21.65 17.98 21.65C17.83 21.65 17.66 21.59 17.54 21.47C17.29 21.22 17.29 20.81 17.54 20.56L18.6 19.5L17.54 18.45C17.29 18.2 17.29 17.79 17.54 17.54C17.79 17.29 18.2 17.29 18.45 17.54L19.49 18.6L20.55 17.54C20.8 17.29 21.22 17.29 21.47 17.54C21.72 17.79 21.72 18.2 21.47 18.45L20.41 19.5L21.47 20.56Z' fill='white'/%3E%3C/svg%3E");
}

.dark-theme .switch .slider {
  background: #171717;
}

.dark-theme .content-page .page-title {
  background: #171717;
  border-color: #171717;
}

.dark-theme .content-page .page-title h1,
.dark-theme .content-page .section-title h2 {
  color: var(--white-color);
}

.dark-theme .content-page .faq-accordion-wrap .accordion-item {
  border-color: #585858;
}

.dark-theme
  .content-page
  .faq-accordion-wrap
  .accordion-item
  .accordion-button {
  color: rgba(255, 255, 255, 0.25);
}

.dark-theme
  .content-page
  .faq-accordion-wrap
  .accordion-item
  .accordion-button:not(.collapsed) {
  color: var(--white-color);
}

.dark-theme .content-page .section-form .form-wrapper textarea.form-control {
  background-color: rgba(255, 255, 255, 0.11);
  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.01);
}

.dark-theme .content-page .report-checklist .form-check .form-check-label h6 {
  color: var(--white-color);
}

.dark-theme .content-page .report-checklist .form-check .form-check-label p {
  color: #949494;
}

.dark-theme .settings-header {
  background: #171717;
  border-color: #171717;
}

.dark-theme .settings-header ul li a.active,
.dark-theme .settings-header ul li a:hover {
  background: var(--black-color);
  color: var(--white-color);
}

.dark-theme
  .setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .user-icon
  label {
  background: var(--black-color);
}

.dark-theme
  .setting-page-wrapper
  .profile-wrapper
  .profile-image-area
  .user-icon
  label
  svg
  path {
  stroke: var(--white-color);
}

.setting-page-wrapper .profile-wrapper .profile-image-area::before {
  background: #171717;
}

.setting-page-wrapper .alert {
  margin: 10px 0;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
}

.setting-page-wrapper .alert.alert-primary {
  background: rgba(59, 180, 74, 10%);
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
}

.notification-view-page .video-wrap .alert {
  position: static;
  transform: none;
  margin: 30px auto;
  background-color: #ff605824;
  max-width: 400px;
  font-weight: 600;
  font-size: 18px;
  border: none;
  text-align: center;
  color: var(--danger-color);
  padding: 20px;
}

.reseller-module .form-reseller.theme-table {
  max-width: 100%;
}

.user-permissions .create-user .create-user-wrapper .filter-wrapper::before {
  background: #f1f1f1;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.user-permissions .create-user .create-user-wrapper .filter-wrapper {
  position: relative;
  padding: 20px;
  margin: 0 0 20px 0;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .filter-wrapper
  .filter-inner
  h4 {
  font-weight: 400;
  font-size: 18px;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .filter-wrapper
  .filter-inner {
  margin: 0 0 20px 0;
}

.user-permissions .create-user .create-user-wrapper .groups-list-wrapper {
}

.user-permissions
  .create-user
  .create-user-wrapper
  .groups-list-wrapper
  .accordion-item {
}

.user-permissions
  .create-user
  .create-user-wrapper
  .groups-list-wrapper
  .accordion-item
  .accordion-button {
  margin: 0;
  background: #f1f1f1;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .groups-list-wrapper
  .accordion-item
  .accordion-button::after {
  display: none;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .filter-wrapper
  .filter-inner:not(:last-of-type) {
  border-bottom: 1px solid #dadada;
  padding-bottom: 20px;
}

.sortable-group {
}

.sortable-group .text-wrap {
  margin: 10px 0;
  background: #ffffff;
  border-radius: 6px;
  gap: 5px;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.sortable-group .text-wrap svg {
  vertical-align: top;
}

.sortable-group .text-wrap .icon-wrap {
  height: 16px;
}

.sortable-group .text-wrap:hover .icon-wrap rect {
  fill: var(--primary-color);
}

.camera-row .card-camera .card-subhead .icon-wrap svg:hover g {
  opacity: 1;
}

.sidebar-theme .sidebar-wrapper .reset-wrapper .dropdown .btn {
  border-radius: 0;
  padding: 5px 0px;
  border: none;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .reset-wrapper
  .dropdown
  .btn
  svg
  path {
  stroke: var(--white-color);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.camera-row .card-camera .card-subhead .icon-wrap svg:hover g path {
  stroke: var(--primary-color);
}

.dark-theme .sortable-group .text-wrap {
  background: #171717;
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .filter-wrapper
  .filter-inner:not(:last-of-type) {
  border-color: #323232;
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .filter-wrapper::before {
  background: #171717;
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .groups-list-wrapper
  .accordion-item
  .accordion-button {
  background: #171717;
}

.dark-theme .theme-table td,
.dark-theme .theme-table th {
  border-color: #383838 !important;
  color: var(--white-color);
}

.dark-theme .btn-delete::before {
  background: #171717;
}

.dark-theme .btn-delete {
  color: #949494;
}

.dark-theme .btn-delete:hover {
  color: var(--white-color);
}

.dark-theme .btn-secondary::before {
  background: #171717;
}

.dark-theme .btn-secondary {
  color: #949494;
}

.dark-theme .btn-secondary:hover {
  color: var(--white-color);
}

.dark-theme .setting-page-wrapper .back-btn {
  color: #949494;
}

.dark-theme .user-permissions .page-heading h3 {
  color: var(--white-color);
}

.dark-theme .user-permissions .create-user .create-user-wrapper::before {
  background: #202020;
}

.dark-theme .form-check label {
  color: var(--white-color);
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .btn-primary:disabled {
  background: #171717 !important;
  color: #949494;
}

.dark-theme .report-analytics .report-analytics-wrap,
.dark-theme .report-analytics {
  border-color: #383838;
  color: var(--white-color);
}

.dark-theme .report-analytics .report-analytics-wrap:hover {
  color: #3bb44a;
}

.dark-theme .report-analytics .report-analytics-wrap svg g path {
  stroke: var(--white-color);
}

.dark-theme .report-analytics .report-analytics-wrap:hover svg g path {
  stroke: var(--primary-color);
}

.dark-theme .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme {
  border-right: 5px solid var(--black-color);
}

.dark-theme
  .site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme
  .camera-header
  .available-heading
  h3,
.dark-theme .devices-page .camera-header .available-heading h2 {
  color: var(--white-color);
}

.dark-theme
  .site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme::before {
  background: #272727;
}

.dark-theme .sidebar-theme .sidebar-wrapper .reset-wrapper .refresh-btn {
  color: #979797;
}

.dark-theme .sidebar-theme .sidebar-wrapper .reset-wrapper .refresh-btn path {
  fill: #979797;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-button {
  color: #aeaeae;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-button::after,
.dark-theme .groups-list-wrapper .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.9201 9.00015L13.4001 15.5202C12.6301 16.2902 11.3701 16.2902 10.6001 15.5202L4.08008 9.00015' stroke='%23949494' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-body {
  background: #171717;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-body
  .form-check
  .form-check-label {
  color: #949494;
}

.dark-theme .sidebar-theme .sidebar-wrapper .reset-wrapper,
.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .all-cameras-wrapper
  .accordion-button,
.dark-theme .sidebar-theme .sidebar-wrapper .date-wrapper {
  border-color: #373737;
}

.dark-theme .date-wrapper h3 {
  color: var(--white-color);
}

.dark-theme .sidebar-theme .sidebar-wrapper .date-wrapper p {
  color: #949494;
}

.dark-theme .sidebar-theme .sidebar-wrapper .time-frame::before {
  background: #373737;
}

.dark-theme .sidebar-theme .sidebar-wrapper .time-frame .header h4 {
  color: var(--white-color);
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .time-frame
  .header
  .switch
  .slider {
  background: #171717;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  .form-check-label {
  background: #171717;
  color: var(--white-color);
  border-color: #171717;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .group-checkbox
  .group-checkbox-wrap
  .form-check
  input:checked
  + .form-check-label {
  color: var(--primary-color);
  background: var(--black-color);
  border-color: var(--black-color);
}

.dark-theme .camera-row .card-camera .card-subhead .icon-wrap svg path {
  stroke: var(--white-color);
}

.dark-theme .camera-row .card-camera .card-subhead {
  background: #171717;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .time-frame
  .date-time-picker
  .input-wrapper
  h4 {
  color: #171717;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .time-frame
  .date-time-picker
  .form-control {
  background-color: #272727;
  color: #aeaeae;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 1.5V3.75' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.5V3.75' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.625 6.81738H15.375' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.4075 11.8272L11.7525 14.4822C11.6475 14.5872 11.55 14.7822 11.5275 14.9247L11.385 15.9372C11.3325 16.3047 11.5875 16.5597 11.955 16.5072L12.9675 16.3647C13.11 16.3422 13.3125 16.2447 13.41 16.1397L16.065 13.4847C16.5225 13.0272 16.74 12.4947 16.065 11.8197C15.3975 11.1522 14.865 11.3697 14.4075 11.8272Z' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.0251 12.21C14.2501 13.02 14.8801 13.65 15.6901 13.875' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375V9' stroke='%23949494' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.99661 10.2754H9.00335' stroke='%23949494' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.22073 10.2754H6.22747' stroke='%23949494' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.22073 12.5254H6.22747' stroke='%23949494' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dark-theme
  .site-header
  .display-wrap
  .display-inputs
  .form-checkbox
  label
  svg
  g {
  opacity: 0.7;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .time-frame
  .date-time-picker
  .rdtPicker {
  background: #373737;
}

.dark-theme .sidebar-theme .sidebar-wrapper th.rdtPrev,
.dark-theme .sidebar-theme .sidebar-wrapper th.rdtNext,
.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .rdtPicker
  thead
  tr:first-of-type
  th.rdtSwitch {
  border-color: #373737;
  background-color: transparent;
}

.dark-theme
  .sidebar-theme
  .sidebar-wrapper
  .rdtPicker
  thead
  tr:first-of-type
  th.rdtSwitch {
  color: var(--white-color);
}

.dark-theme .sidebar-theme .sidebar-wrapper .rdtPicker td.rdtActive,
.dark-theme .sidebar-theme .sidebar-wrapper .rdtPicker td.rdtActive:hover {
  background: var(--white-color);
  color: var(--black-color);
}

.dark-theme
  .camera-view
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .mata-details
  .title:not(:last-of-type) {
  border-right: 1px solid #171717;
}

.dark-theme .camera-view .camera-row .card-camera .card-subhead {
  background: #272727;
}

.dark-theme
  .camera-view
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .mata-details
  .title {
  color: #949494;
}

.dark-theme
  .camera-view
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .mata-details
  .title
  .icon
  svg
  path {
  stroke: #949494;
}

.dark-theme .camera-view .camera-row .card-camera .card-subhead .icon-wrap svg {
  opacity: 0.25;
}

.dark-theme .camera-view .camera-row .card-camera .card-header {
  background: #171717;
}

.dark-theme .camera-view .camera-row .card-camera {
  border-color: #171717;
}

.dark-theme
  .site-main-wrapper
  .main-wrapper
  .main-page-height
  .sidebar-theme.sidebar-details {
  background: #373737;
}

.dark-theme .sidebar-theme.sidebar-details .sidebar-wrapper .camera-list li {
  border-color: #171717;
}

.dark-theme
  .sidebar-theme.sidebar-details
  .sidebar-wrapper
  .camera-list
  li.active {
  background: #000;
  border-color: #000;
  color: var(--white-color);
}

.dark-theme
  .sidebar-theme.sidebar-details
  .sidebar-wrapper
  .camera-list
  li.active
  .camera-id {
  color: var(--white-color);
}

.dark-theme .sidebar-theme.sidebar-details .camera-header .dropdown-wrap,
.dark-theme
  .sidebar-theme.sidebar-details
  .camera-header
  .dropdown-wrap
  .dropdown
  .btn {
  color: var(--white-color);
}

.dark-theme
  .sidebar-theme.sidebar-details
  .camera-header
  .dropdown-wrap
  .dropdown
  .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.92 9.00015L13.4 15.5202C12.63 16.2902 11.37 16.2902 10.6 15.5202L4.07999 9.00015' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dark-theme
  .sidebar-theme.sidebar-details
  .camera-header
  .dropdown-wrap
  .dropdown
  .dropdown-menu {
  border: 1px solid #383838;
}

.dark-theme
  .devices-page
  .camera-header.details-header
  .available-heading
  h2
  .edit-btn,
.dark-theme .subscription-box .heading .edit-btn {
  background: #302f2f;
}

.dark-theme
  .devices-page
  .camera-header.details-header
  .available-heading
  h2
  .edit-btn
  svg
  path,
.dark-theme .subscription-box .heading .edit-btn svg path {
  stroke: var(--white-color);
}

.dark-theme
  .devices-page
  .camera-header.details-header
  .dropdown
  .btnn
  svg
  path {
  stroke: #171717;
}

.dark-theme .details-view .header-tabs {
  background: #000000;
}

.dark-theme .details-view .header-tabs li.nav-item .nav-link.active,
.dark-theme .details-view .header-tabs li.nav-item .nav-link:hover {
  color: #fff;
}

.dark-theme .add-new-device-page .theme-form-wrapper .edit-btn {
  color: #fff;
}

.dark-theme .add-new-device-page .theme-form-wrapper .edit-btn svg path {
  stroke: #fff;
}

.dark-theme .add-new-device-page .theme-form-wrapper .edit-btn {
  color: #fff;
}

.dark-theme .add-new-device-page .theme-form-wrapper .edit-btn svg path {
  stroke: #fff;
}

.dark-theme .notification-view-page {
  background: #171717;
}

.dark-theme
  .notification-view-page
  .notification-list.add-new-device-page
  .day {
  background: #171717;
  color: #949494;
}

.dark-theme
  .notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper {
  background: #373737;
  color: #949494;
  border-color: #171717;
  border-left: 3px solid var(--danger-color);
}

.dark-theme .notification-view-page .video-wrap {
  background: #171717;
}

.dark-theme
  .notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper.active,
.dark-theme
  .notification-view-page
  .notification-list.add-new-device-page
  .notification-wrapper:hover {
  background: #272727;
  border-left-color: var(--primary-color);
}

.dark-theme .notification-view-page .video-wrap .text-wrap .text-inner .title {
  color: #949494;
}

.dark-theme
  .notification-view-page
  .video-wrap
  .text-wrap
  .text-inner
  .title
  svg
  path {
  stroke: #949494;
}

.dark-theme
  .notification-view-page
  .video-wrap
  .download-button
  .download-btn
  svg
  g {
  opacity: 1;
}

.dark-theme
  .notification-view-page
  .video-wrap
  .download-button
  .download-btn
  svg
  g
  path {
  stroke: #949494;
}

.dark-theme .notes-view-page .card {
  background: #171717;
}

.dark-theme .notes-view-page .card .card-header {
  background: #000000;
  border-bottom: 1px solid #000000;
}

.dark-theme .notes-view-page .card .card-header h3 {
  color: #fff;
}

.dark-theme .main-wrapper .notes-view-page .addCard {
  border: 2px solid #171717;
}

.dark-theme .content-page .content-page-wrapper .section-text {
  color: #949494;
}

.dark-theme .content-page .content-page-wrapper .section-text.element h4 {
  color: #fff;
}

.dark-theme .sidebar-theme .camera-switch-wrap .check-switch {
  border: 1px solid #272727;
}

.dark-theme .sidebar-theme.groups-sidebar .camera-wrap {
  background: #171717;
  border-color: #171717;
}

.dark-theme
  .sidebar-theme.groups-sidebar
  .camera-wrap
  .form-check
  .form-check-label {
  color: #686868;
}

.dark-theme
  .sidebar-theme.groups-sidebar
  .camera-wrap
  .button-wrap
  .setting-icon
  svg
  path {
  fill: #272727;
}

.dark-theme
  .sidebar-theme.groups-sidebar
  .camera-wrap
  .form-check
  input:disabled
  + .form-check-label::before {
  border-color: #272727;
}

.dark-theme .groups-list-wrapper .accordion-item .accordion-button {
  background: #171717;
  color: #fff;
  border: 1px solid #171717;
}

.dark-theme
  .groups-list-wrapper
  .accordion-item
  .accordion-button:not(.collapsed) {
  background: #585858;
  border-color: #585858;
}

.dark-theme .groups-list-wrapper .accordion-item .camera-wrap .left-side {
  background: #373737;
}

.dark-theme
  .groups-list-wrapper
  .accordion-item
  .camera-wrap
  .left-side
  .camera-title {
  color: #fff;
}

.dark-theme
  .groups-list-wrapper
  .accordion-item
  .camera-wrap
  .delete-button-wrap
  .setting-icon {
  background: #2e2e2e;
}

.dark-theme
  .groups-list-wrapper
  .accordion-item
  .camera-wrap
  .button-wrap
  .setting-icon
  path {
  fill: #272727;
}

.dark-theme
  .groups-list-wrapper
  .accordion-item
  .camera-wrap:not(:last-of-type) {
  margin-bottom: 10px;
}

.dark-theme .subscription-group .subscription-box::before {
  background: #202020;
}

.dark-theme .subscription-group .subscription-box .heading h4,
.dark-theme .subscription-group .subscription-box .data-wrapper .data-list {
  color: #fff;
}

.dark-theme .subscription-group .subscription-box .data-wrapper .data-list,
.dark-theme .subscription-group .subscription-box .heading {
  border-color: rgba(255, 255, 255, 0.11);
}

.dark-theme .devices-storage-wrapper .devices-storage-list {
  color: #fff;
}

.dark-theme .devices-storage-wrapper .devices-storage-list::before {
  background: #202020;
}

.dark-theme
  .devices-storage-wrapper
  .devices-storage-list
  .data-list:not(:last-of-type) {
  border-color: rgba(255, 255, 255, 0.11);
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-control {
  background-color: rgba(255, 255, 255, 0.11);
}

.dark-theme .form-text {
  color: #fff;
}

.dark-theme
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu {
  background: #000000;
  border-color: rgba(255, 255, 255, 0.25);
}

.dark-theme
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  .notif-header,
.dark-theme
  .camera-row
  .card-camera
  .card-header
  .camera-details
  .dropdown-menu
  ul
  li
  .dropdown-item {
  color: #fff;
  border-color: rgb(255, 255, 255, 0.25);
}

/* loader */

.loader-div {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-self: center;
}

.loader {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dark-theme .btn-sidebar-toggle path {
  stroke: #fff;
}

.groups-list-wrapper .accordion-item .accordion-button.link-btn {
  text-decoration: none;
  background: #ffffff;
  border-color: #fff;
}

.dark-theme .groups-list-wrapper .accordion-item .accordion-button.link-btn {
  background: #171717;
  border-color: #171717;
}

.groups-list-wrapper .accordion-item .accordion-button.link-btn::after {
  transform: rotate(-90deg);
}

.groups-list-wrapper .accordion-item.list-item .form-check-label {
  height: 24px;
}

.devices-page .camera-header .button-wrap .btn-delete.inactive {
  opacity: 0.5;
}

.dark-theme .notification-view-page .notification-list.add-new-device-page {
  border-color: #000;
}

/* MULTI SPINNER */

.multi-spinner-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 30px auto;
  overflow: hidden;
}

.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #27ca40;
  border-radius: 50%;
  -webkit-animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 5s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}

.no-subscriptions .alert-primary {
  background: rgba(59, 180, 74, 0.46);
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  border: none;
  text-align: center;
  max-width: 240px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.leaflet-container {
  width: 100%;
  height: 80vh;
}

.camera-view .camera-row .card-camera .card-camera-body video {
  position: absolute;
  width: 100%;
  height: 100%;
}

.camera-view .camera-row .card-camera .card-camera-body {
  padding-bottom: calc(100vh - 210px);
}

.dark-theme .details-view .header-tabs li.nav-item .nav-link {
  color: #949494;
}

.dark-theme .camera-row .card-camera .card-subhead .text-wrapper .title {
  color: #aeaeae;
}

.dark-theme
  .camera-row
  .card-camera
  .card-subhead
  .text-wrapper
  .title
  .icon
  svg
  path {
  stroke: #aeaeae;
}

.dark-theme
  .user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-control[multiple]
  option {
  color: #fff;
}

.user-permissions
  .create-user
  .create-user-wrapper
  .theme-form-wrapper
  .form-control[multiple] {
  background-image: none !important;
}

/**************Date Range********************/
.date-range-picker {
  /* min-height: inherit;
  font-weight: 400;
  font-size: 15px;
  color: #0F2744 !important;
  gap: 5px;
  border: 1px solid #C1C7CF;
  border-radius: 2px;
  position: relative;
  padding: 10px 13px;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center; */
}

body .daterangepicker {
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
}

body .daterangepicker::before {
  display: none;
}

body .daterangepicker .drp-calendar.left {
  border: none !important;
}

body .daterangepicker .ranges {
  margin: 0 !important;
  padding: 15px;
}

body .daterangepicker::after {
  display: none;
}

body .daterangepicker .ranges li {
  font-size: 14px;
  padding: 0.55rem 0.7rem;
}

body .daterangepicker .ranges li.active {
  background: rgb(255 255 255);
  color: var(--primary-color);
  font-weight: 600;
}

.daterangepicker .ranges li:hover {
  background: rgb(255 255 255);
  color: var(--primary-color);
}

body .daterangepicker td.in-range {
  background: rgb(59 180 74 / 28%);
}

body.dark-theme .daterangepicker td.in-range {
  color: #fff;
}

body.dark-theme .daterangepicker td.in-range {
  background: rgba(59, 180, 73, 0.717);
}

body.dark-theme .daterangepicker td.off,
body.dark-theme .daterangepicker td.off.in-range,
body.dark-theme .daterangepicker td.off.start-date,
body.dark-theme .daterangepicker td.off.end-date {
  background-color: transparent;
  border-color: transparent;
  color: #999;
}

body .daterangepicker td.active,
body .daterangepicker td.active:hover,
body.dark-theme .daterangepicker td.end-date {
  background: var(--primary-color);
}

body .daterangepicker .calendar-table th,
body .daterangepicker .calendar-table td {
  font-size: 14px;
  font-weight: 400;
}

body .daterangepicker .calendar-table th {
  color: #728095;
}

body .daterangepicker.show-calendar .drp-buttons {
  padding: 15px;
}

body .daterangepicker.show-calendar .drp-buttons .drp-selected {
  float: left;
  padding-top: 12px;
  font-size: 14px;
  color: #0f2744;
}

body .daterangepicker.show-calendar .drp-buttons .btn {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 26px;
  border-radius: 4px !important;
}

body .daterangepicker.show-calendar .drp-buttons .cancelBtn {
  border: 1px solid var(--danger-color);
  border-radius: 2px;
  color: var(--danger-color);
}

body .daterangepicker.show-calendar .drp-buttons .applyBtn {
  margin-left: 15px;
}

body .daterangepicker.show-calendar .drp-buttons .cancelBtn:hover {
  background: var(--danger-color);
  color: #fff;
}

body .daterangepicker .drp-calendar {
  /* padding-top: 15px !important; */
}

body .daterangepicker .drp-calendar .prev,
body .daterangepicker .drp-calendar .next {
  /* display: none; */
}

.daterangepicker th.month {
  width: auto;
}

body .daterangepicker .drp-calendar thead th.month {
  color: #000;
}

body.dark-theme .daterangepicker .drp-calendar thead th.month {
  font-weight: 700;
  color: #fff;
}

body.dark-theme .daterangepicker .calendar-table .next,
body.dark-theme .daterangepicker .calendar-table .prev {
  background-color: transparent !important;
}

body.dark-theme .daterangepicker .calendar-table .next span,
body.dark-theme .daterangepicker .calendar-table .prev span {
  border-color: #fff;
}

body .daterangepicker .drp-calendar thead th:empty {
  /* display: none; */
}

body .daterangepicker .drp-calendar thead th.month select {
  border: none;
  height: 35px;
  font-weight: 400;
  font-size: 14px;
  color: #0f2744;
  padding: 0 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 35px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-color: #f5f6f8;
  border-radius: 3px;
  width: auto;
  outline: none;
}

body .daterangepicker .calendar-table td:hover {
  color: #fff;
  background-color: var(--primary-color);
}

body.dark-theme .daterangepicker .drp-calendar thead th.month select {
  background-color: #373737;
  color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.94 6.71249L10.05 11.6025C9.4725 12.18 8.5275 12.18 7.95 11.6025L3.06 6.71249' stroke='%23ffffff' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

body .daterangepicker .drp-calendar thead th.month select.monthselect {
  margin-right: 13px;
}

body.dark-theme .daterangepicker {
  background: #171717;
}

body.dark-theme .daterangepicker.show-calendar .drp-buttons .drp-selected {
  color: #fff;
}

.daterangepicker .calendar-table {
  border: none;
}

body.dark-theme .daterangepicker .calendar-table {
  background-color: transparent;
}

.daterangepicker.show-calendar .ranges:empty {
  display: none;
}

button.accordion-button.no-icon::after {
  display: none;
}

.dark-theme .modal-delete .button-wrap .btn-cancel:not(:hover) {
  color: #a9a9a9;
}

.no-underline {
  text-decoration: none;
}

.pagination-btn {
  margin-top: 14px;
}

.page-search {
  border: 3px solid rgb(248, 8, 8);
  width: 200px;
}

.search-btn {
  width: 100px;
  background-color: chartreuse;
  margin-left: 3px;
}

.group-camera-bodys {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.group-camera-body {
  width: 100%;
  margin-left: 25px;
}

.delete-button-wraps .setting-icons {
  background: #2a2a2b;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none;
  margin-left: 10px;
}

/* 
.camera-row > div{
   width: 20%;
} */

.latitude-input::-webkit-outer-spin-button,
.latitude-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.latitude-input {
  -moz-appearance: textfield;
  appearance: textfield;
}

.recording-wrapper {
  width: -webkit-fill-available;
}

/************New UI****************/
.upload-wrapper {
  margin: 0 0 15px 0;
}

.upload-wrapper .upload-info {
  font-size: 14px;
  margin: 0;
  opacity: 0.5;
}

.upload-wrapper .upload-file .upload-lbl,
.upload-wrapper .input-group .file-input {
  cursor: pointer;
}

.upload-wrapper .upload-file .upload-lbl svg path {
  fill: #000;
  opacity: 0.3;
}

.dark-theme .upload-wrapper .upload-file .upload-lbl svg path {
  fill: #fff;
  opacity: 0.3;
}

.upload-wrapper .upload-file .upload-lbl svg {
  width: 20px;
  height: auto;
}

.uploaded-img {
  margin-top: 20px;
  position: relative;
  margin: 0px auto 0 auto;
  max-width: 500px;
}

.uploaded-img .remove-icon {
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 8px;
  z-index: 1;
}

.uploaded-img .img-wrap img,
.uploaded-img .img-wrap iframe,
.uploaded-img .img-wrap svg {
  width: 100%;
  max-width: 100%;
  margin: auto;
}

.dark-theme .right-navigation-menu .account-wrap h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.list-view-site-view {
  background: #f1f1f1;
  border-radius: 30px;
  padding: 10px;
}

.dark-theme .list-view-site-view {
  background: #202020;
}

.list-view-site-view .list-btn {
  border: none;
  background: #fff;
  color: #171717;
  border-radius: 40px;
  padding: 5px 20px;
  font-weight: 500;
  white-space: nowrap;
}

.list-view-site-view .list-btn + .list-btn {
  margin-left: 10px;
}

.list-view-site-view .list-btn.active {
  color: var(--primary-color) !important;
}

.dark-theme .list-view-site-view .list-btn {
  background: var(--black-color);
  color: #fff;
}

.timeline-wrapper .timeline-outer .timeline .hour-listing-new ul.hours-list {
  margin-bottom: 10px;
}

.timeline-wrapper .timeline-outer .timeline .hour-listing-new ul.hours-list li {
  background: #ddd;
  min-width: inherit;
  padding: 10px 7px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.timeline-wrapper
  .timeline-outer
  .timeline
  .hour-listing-new
  ul.hours-list
  li:not(:last-of-type) {
  margin-right: 6px;
}

.timeline-wrapper
  .timeline-outer
  .timeline
  .hour-listing-new
  ul.hours-list
  li.inactive {
  background: var(--danger-color) !important;
  cursor: pointer;
}

.dark-theme
  .timeline-wrapper
  .timeline-outer
  .timeline
  .hour-listing-new
  ul.hours-list
  li {
  background: #fff;
  color: #000;
}

.timeline-wrapper .timeline-outer.hour-list-quarter .timeline {
  width: 100%;
}

.form-check-label.green-border::before {
  border: 1px solid var(--primary-color);
  border-radius: 2px;
}

.btn-secondary.orange-color::before {
  background: #e67223;
}

.btn-secondary.orange-color {
  color: #fff;
}

.btn-secondary.orange-color::after {
  background: #f63b3b;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .delete-button-wraps
  .setting-icons {
  padding: 0;
  cursor: pointer;
  pointer-events: auto;
}

.camera-row
  .card-camera
  .card-header
  .camera-details
  .delete-button-wraps
  .setting-icons
  svg {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.user-permissions .create-user .create-user-wrapper.site-accesible .heading h4 {
  font-weight: 400;
  font-size: 20px;
}

.user-permissions
  .create-user
  .create-user-wrapper.site-accesible
  .form-check-input[disabled]
  ~ .form-check-label::before {
  background: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.28339 10.7165L10.7167 1.2832" stroke="%23FF6058" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.7167 10.7165L1.28339 1.2832" stroke="%23FF6058" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: center;
}

.user-permissions
  .create-user
  .create-user-wrapper.site-accesible
  .form-check-input[disabled]
  ~ .form-check-label {
  opacity: 1;
  cursor: not-allowed;
}

.table-dropdown.dropdown .btn {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.table-dropdown.dropdown .btn:hover {
  background: #f4f4f4;
}

.dark-theme .table-dropdown.dropdown .btn:hover {
  background: #383838;
}

.tab-search.search-filter {
  margin-bottom: 25px;
}

.dataTables_length {
  display: none;
}

.search-filter .search-filter-padding {
  padding-right: 30px;
}

.search-filter .search-filter-padding .custom-search-input,
.page-heading .search-filter-padding .custom-search-input {
  background-repeat: no-repeat;
  background-position: 10px 50%;
  padding-left: 40px;
  background-size: 20px;
  background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.58268 17.5003C13.9549 17.5003 17.4993 13.9559 17.4993 9.58366C17.4993 5.2114 13.9549 1.66699 9.58268 1.66699C5.21043 1.66699 1.66602 5.2114 1.66602 9.58366C1.66602 13.9559 5.21043 17.5003 9.58268 17.5003Z" stroke="%23C3C8CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.3327 18.3337L16.666 16.667" stroke="%23C3C8CC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 2px;
}

.search-filter .drop-wrap label {
  margin-right: 10px;
  white-space: nowrap;
}

.search-filter .drop-wrap button {
  min-height: 46px;
  min-width: 210px;
  text-align: left;
  display: flex;
  align-items: center;
  color: var(--text-color);
  border-radius: 0px;
  padding: 8px 13px;
  background-color: var(--white-color);
  font-size: 20px;
}

.ques-options button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url('data:image/svg+xml,<svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9201 1L10.4001 7.52C9.63008 8.29 8.37008 8.29 7.60008 7.52L1.08008 1" stroke="%23333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  border: none;
}

.dark-theme .ques-options button::after {
  background-image: url('data:image/svg+xml,<svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9201 1L10.4001 7.52C9.63008 8.29 8.37008 8.29 7.60008 7.52L1.08008 1" stroke="%23fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.search-filter .drop-wrap button.show {
  border: 1px solid #3bb44a !important;
}

.dark-theme .search-filter .drop-wrap button {
  background-color: rgba(255, 255, 255, 0.11);
  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.01);
}

.dark-theme .search-filter .drop-wrap button.show {
  background: transparent;
}

.canvas-container {
  position: relative;
}

.canvas-container .main-canvas {
  position: relative;
}

.overlay-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 20rem !important;
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none !important;
}

.knob {
  display: flex;
  position: relative;
}
.knob .ticks {
  position: absolute;
}
.knob .ticks .tick {
  position: absolute;
  background: black;
  box-shadow: inset 0 0 0 0 black;
  width: 3px;
  transition: box-shadow 0.5s;
}
.knob .ticks .tick.active {
  box-shadow: inset 0 0 5px 2px #3bb44a, 0 0 0 1px #33995d;
}
.knob.outer {
  border-radius: 50%;
  border: 1px solid #222;
  border-bottom: 5px solid #222;
  background-image: radial-gradient(100% 70%, #666 6%, #333 90%);
  box-shadow: 0 5px 15px 2px black, 0 0 5px 3px black, 0 0 0 12px #444;
}
.knob.inner {
  border-radius: 50%;
}
.knob.inner .grip {
  position: absolute;
  width: 5%;
  height: 5%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #3bb44a;
  box-shadow: 0 0 3px 1px black;
}

.stage-class > .konvajs-content,
.stage-class > .konvajs-content > canvas {
  /* width: 100% !important; */
}

.theme-table .button-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.theme-table .button-wrap .btn.btn-rounded {
  max-width: unset;
  font-size: 0px;
  padding: 0;
  width: 34px;
  height: 34px;
  border-radius: 3px;
  background-color: transparent;
  border: none;
  background-position: center !important;
  background-repeat: no-repeat !important;
  transition: 0.3s;
}

.theme-table .button-wrap .btn.btn-rounded::before,
.theme-table .button-wrap .btn.btn-rounded::after {
  display: none;
}

.theme-table .button-wrap .btn + .btn {
  /* margin-left: 10px; */
}

.theme-table .button-wrap .btn.btn-rounded.edit {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.2603 3.59973L5.05034 12.2897C4.74034 12.6197 4.44034 13.2697 4.38034 13.7197L4.01034 16.9597C3.88034 18.1297 4.72034 18.9297 5.88034 18.7297L9.10034 18.1797C9.55034 18.0997 10.1803 17.7697 10.4903 17.4297L18.7003 8.73973C20.1203 7.23973 20.7603 5.52973 18.5503 3.43973C16.3503 1.36973 14.6803 2.09973 13.2603 3.59973Z" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.8896 5.0498C12.3196 7.8098 14.5596 9.9198 17.3396 10.1998" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 22H21" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded.enable {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.4404 18L17.4304 18.99L19.5604 17.02" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded.disable {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.41016 22C3.41016 18.13 7.26018 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.4897 17.98H16.5098" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded.delete {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.8504 9.14014L18.2004 19.2101C18.0904 20.7801 18.0004 22.0001 15.2104 22.0001H8.79039C6.00039 22.0001 5.91039 20.7801 5.80039 19.2101L5.15039 9.14014" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.3301 16.5H13.6601" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 12.5H14.5" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded.reset {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8901 5.0799C14.0201 4.8199 13.0601 4.6499 12.0001 4.6499C7.21008 4.6499 3.33008 8.5299 3.33008 13.3199C3.33008 18.1199 7.21008 21.9999 12.0001 21.9999C16.7901 21.9999 20.6701 18.1199 20.6701 13.3299C20.6701 11.5499 20.1301 9.8899 19.2101 8.5099" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.1302 5.32L13.2402 2" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.1298 5.31982L12.7598 7.77982" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded.resend {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1167 19.204C15.0083 18.8415 14.9375 18.4415 14.9375 17.9998C14.9375 16.004 16.5542 14.3873 18.55 14.3873C20.5458 14.3873 22.1625 16.004 22.1625 17.9998C22.1625 19.9956 20.5458 21.6123 18.55 21.6123" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.2163 19.7212L13.833 18.517" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.2168 19.7212L16.2418 18.317" stroke="%23484848" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V11.5" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="%23484848" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.theme-table .button-wrap .btn.btn-rounded {
  opacity: 0.5;
}

.theme-table .button-wrap .btn.btn-rounded:hover {
  background-color: #dadada !important;
  opacity: 1;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.edit {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.2603 3.59973L5.05034 12.2897C4.74034 12.6197 4.44034 13.2697 4.38034 13.7197L4.01034 16.9597C3.88034 18.1297 4.72034 18.9297 5.88034 18.7297L9.10034 18.1797C9.55034 18.0997 10.1803 17.7697 10.4903 17.4297L18.7003 8.73973C20.1203 7.23973 20.7603 5.52973 18.5503 3.43973C16.3503 1.36973 14.6803 2.09973 13.2603 3.59973Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.8896 5.0498C12.3196 7.8098 14.5596 9.9198 17.3396 10.1998" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M3 22H21" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.enable {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.4404 18L17.4304 18.99L19.5604 17.02" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.disable {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.41016 22C3.41016 18.13 7.26018 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.4897 17.98H16.5098" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.delete {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.8504 9.14014L18.2004 19.2101C18.0904 20.7801 18.0004 22.0001 15.2104 22.0001H8.79039C6.00039 22.0001 5.91039 20.7801 5.80039 19.2101L5.15039 9.14014" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.3301 16.5H13.6601" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 12.5H14.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.reset {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.8901 5.0799C14.0201 4.8199 13.0601 4.6499 12.0001 4.6499C7.21008 4.6499 3.33008 8.5299 3.33008 13.3199C3.33008 18.1199 7.21008 21.9999 12.0001 21.9999C16.7901 21.9999 20.6701 18.1199 20.6701 13.3299C20.6701 11.5499 20.1301 9.8899 19.2101 8.5099" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.1302 5.32L13.2402 2" stroke="white" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.1298 5.31982L12.7598 7.77982" stroke="white" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded.resend {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.1167 19.204C15.0083 18.8415 14.9375 18.4415 14.9375 17.9998C14.9375 16.004 16.5542 14.3873 18.55 14.3873C20.5458 14.3873 22.1625 16.004 22.1625 17.9998C22.1625 19.9956 20.5458 21.6123 18.55 21.6123" stroke="%23ffffff" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.2163 19.7212L13.833 18.517" stroke="%23ffffff" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.2168 19.7212L16.2418 18.317" stroke="%23ffffff" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V11.5" stroke="%23ffffff" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="%23ffffff" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded {
  opacity: 0.5;
}

.dark-theme .theme-table .button-wrap .btn.btn-rounded:hover {
  background-color: #171717 !important;
  opacity: 1;
}

.theme-table tbody tr:hover td {
  background: #f1f1f1;
}
.dark-theme .theme-table tbody tr:hover td {
  background: #000000;
}

.theme-form-wrapper .react-select-container,
.dark-theme .theme-form-wrapper .react-select-container {
  padding: unset !important;
}

.theme-form-wrapper .react-select-container .react-select__control,
.dark-theme .theme-form-wrapper .react-select-container .react-select__control {
  border: unset !important;
}

.theme-form-wrapper .form-control .react-select__control,
.dark-theme .theme-form-wrapper .form-control .react-select__control {
  background-color: rgba(255, 255, 255, 0.11);

  color: var(--white-color);
  border: 1px solid rgba(255, 255, 255, 0.01);
}

.dark-theme .theme-form-wrapper .react-select-container .react-select__menu {
  background-color: #373737;
}

.dark-theme
  .theme-form-wrapper
  .react-select-container
  .react-select__value-container--has-value
  .react-select__single-value {
  color: #fff;
}

.dark-theme
  .theme-form-wrapper
  .react-select-container
  .react-select__menu
  .react-select__menu-list
  .react-select__option--is-focused {
  background-color: rgba(255, 255, 255, 0.11);
}

.theme-table .table-responsive table tbody tr .inactive {
  color: red;
}

@media (max-width: 480px) {
  .map-view-resize {
    width: 90% !important;
  }
}

.stage-class > div {
  overflow-x: auto !important;
}

/* fullScreenModal css */
.modal__wrapper {
  position: absolute;
  background: #fff;
  text-align: center;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.full-screen-modal .modal__wrapper .btn-close {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: white !important;
}

.recording-camera-btn {
  border: none;
  background: #171717;
  color: white;
  border-radius: 40px;
  padding: 10px 40px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: uppercase;
}

.recording-camera-btn.active {
  background: var(--primary-color);;
}

.form-check .camera-config::before {
  right: 10px;
  left: unset;
}

.form-check .camera-config {
  padding-left: 0 ;
  padding-right: 1.8rem;
}
