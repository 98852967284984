.timeline-wrapper .timeline-outer {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: calc(100vw - 359px);
  overflow: auto;
}

.timeline-wrapper {
  margin-bottom: 20px;
}

.timeline-wrapper .timeline-outer .timeline {
  box-shadow: 0px 8px 24px rgb(21 21 22 / 4%);
  border-radius: 4px;
  background: #fff;
  padding: 10px 15px 0px 15px;
  position: relative;
}

.timeline-wrapper .timeline-outer .timeline .hours-time {
  display: flex !important;
  justify-content: space-between !important;
}

.timeline-wrapper .timeline-outer .timeline ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap,
.timeline-wrapper .timeline-outer .timeline ul.hours-list li {
  min-width: 80px;
}

.timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap li {
  height: 50px;
  width: 1px;
  /* background: #b2b2b2; */
}

.timeline-wrapper .timeline-outer .timeline ul.hours-list {
  display: flex;
  justify-content: space-evenly;
  width: inherit;
  margin-bottom: 0px;
}

.timeline-wrapper .timeline-outer .timeline ul.hours-list li {
  width: 100%;
  text-align: left;
  /* margin-left: -26px; */
}


.timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap li.active {
  background: var(--danger-color) !important;
  cursor: pointer;
}

.timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap li.selected {
  background: #000 !important;
}

.dark-theme .timeline-wrapper .timeline-outer .timeline .hours-time .hours-wrap li.selected {
  background: #fff !important;
}

.dark-theme .timeline-wrapper .timeline-outer .timeline {
  background: #000;
}

.timeline-wrapper .heading {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.timeline-wrapper .heading h4 {
  /* flex-grow: 1; */
  /* margin: 0 10px 0 0; */
}

.timeline-wrapper .heading .button-wrap .btn {
  font-size: 0px;
  width: 42px;
  height: 42px;
  background: rgba(0, 0, 0, 0.3);
  margin: 5px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}

.timeline-wrapper .heading .button-wrap .btn.button-prev {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.1201 5.44006L11.4268 14.1334C10.4001 15.1601 10.4001 16.8401 11.4268 17.8667L20.1201 26.5601' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.timeline-wrapper .heading .button-wrap .btn.button-next {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8799 26.5599L20.5732 17.8666C21.5999 16.8399 21.5999 15.1599 20.5732 14.1333L11.8799 5.43994' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 10px;
  }

  ::-webkit-scrollbar-button:vertical:increment {
    background-color: #fff;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    -webkit-border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    width: 50px;
    -webkit-border-radius: 3px;
  }
}

@media (max-width: 991.98px) {
  .timeline-wrapper .heading h4 {
    font-size: 16px;
  }

  .timeline-wrapper .timeline-outer {
    max-width: calc(100vw - 36px);
  }
}

@media (max-width: 767.98px) {
  .timeline-wrapper .heading .button-wrap .btn {
    width: 32px;
    height: 32px;
    background-size: 15px;
  }
}