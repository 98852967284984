/* // Large devices (desktops, less than 1600px) */
@media (max-width: 1599.98px) {

    .camera-row .card-camera .card-header .camera-details .camera-name {
        font-size: 15px;
    }

    .camera-view .camera-row .card-camera .card-header .camera-details .camera-name {
        font-size: 18px;
    }

    .details-view .header-tabs li.nav-item .nav-link {
        font-size: 16px;
    }

    .no-subscriptions .alert-primary {
        font-size: 14px;
    }

    .reseller-module .resellter-sidebar .menu-wrapper ul li a {
        font-size: 15px;
        padding: 12px 0;
    }

    .reseller-module .resellter-sidebar {
        width: 340px;
    }

    .camera-row .card-camera .card-subhead .icon-wrap svg {
        width: 40px;
        height: 40px;
    }

    .camera-row .card-camera .card-subhead {
        padding-left: 15px;
    }

    .left-navigation-menu .side-navigation-panel .side-navigation-panel-select-option {
        padding: 20px 30px !important;
    }

    .devices-page .camera-header .filter-list li button, .theme-form-wrapper .form-control, .theme-table thead th, .report-analytics .report-analytics-wrap, .right-navigation-menu .signout .signout-btn, .content-page .section-title p, .search-filter .drop-wrap button {
        font-size: 15px;
    }

    .add-new-device-page .theme-form-wrapper .col-form-label, .settings-header ul li a, .theme-form-wrapper .col-form-label, .theme-table td, .theme-table th, .site-header .add-device-wrap .refresh, .site-header .add-device-wrap .add-device, .site-header .display-wrap .title, .site-header .search-profile-wrap .search-wrap .form-control, .right-navigation-menu .account-wrap, .content-page .faq-accordion-wrap .accordion-item .accordion-body, .content-page .section-form .form-wrapper .form-control, .content-page .report-checklist .form-check .form-check-label p, .camera-row .card-camera .card-subhead .text-wrapper .title, .group-checkbox .group-checkbox-wrap .form-check-label, .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme .sidebar-wrapper .time-frame .header h4, .group-checkbox .col-form-label, .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme .sidebar-wrapper .date-wrapper p, .dark-theme .right-navigation-menu .account-wrap h4 {
        font-size: 14px;
    }

    .site-header .logo-wrap .camera-dropdown, .devices-page .camera-header .available-heading h2 {
        font-size: 20px;
    }

    .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme .camera-header .available-heading h3 {
        font-size: 18px;
        padding: 11px 0;
        line-height: 1.23;
    }

    .date-wrapper h3 {
        font-size: 25px;
    }

    .settings-header ul li a {
        padding: 13px 13px;
        gap: 8px;
    }

    .content-page .page-title h1, .content-page .section-title h2 {
        font-size: 30px;
    }

    .content-page .faq-accordion-wrap .accordion-item .accordion-button, .content-page .report-checklist .form-check .form-check-label h6 {
        font-size: 18px;
    }

    .left-navigation-menu .side-navigation-panel .side-navigation-panel-select-option .side-navigation-panel-select-option-text {
        font-size: 16px;
    }

    .left-navigation-menu .side-navigation-panel .side-navigation-panel-select-option .icon {
        width: 30px;
        height: 30px;
        margin-right: 20px;
    }

    .site-header .search-profile-wrap .search-wrap {
        max-width: 320px;
        min-width: 320px;
    }

    .content-page .report-checklist .form-check .form-check-label::before {
        width: 25px;
        height: 25px;
        border-radius: 4px;
    }
}

/* // Large devices (desktops, less than 1400px) */
@media (max-width: 1399.98px) {
    .site-header .search-profile-wrap .search-wrap {
        max-width: 250px;
        min-width: 250px;
    }

    .site-header .add-device-wrap .add-device {
        margin: 0 20px;
    }

    .terms-row .sidebar-left {
        width: 300px;
        padding-right: 30px;
    }

    .terms-row .flex-grow-1 {
        max-width: calc(100% - 300px);
    }

    .content-page .content-page-wrapper .section-text.element h4 {
        font-size: 20px;
    }

    .account-details-form.profile-wrapper .theme-form-wrapper .flex-grow-1 {
        max-width: 350px;
        padding-left: 15px;
    }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .add-modal .modal-dialog .modal-content .modal-body {
        padding: 25px;
    }

    .details-view .add-new-device-page .theme-form-wrapper {
        padding: 30px;
    }

    .right-navigation-menu .account-wrap {
        padding-bottom: 16px;
    }

    .notification-view-page .video-wrap.inline-video {
        height: auto;
        margin-bottom: 10px;
    }

    .notification-view-page .notification-list.add-new-device-page .notification-wrapper {
        border-width: 0px 0;
        margin-top: 3px !important;
    }

    .site-header .search-profile-wrap {
        flex-grow: 1 !important;
    }

    .site-header .display-wrap {
        display: none !important;
    }

    .devices-page .add-device {
        font-size: 25px;
        width: 330px;
        height: 330px;
    }

    .devices-page .add-device svg {
        margin-bottom: 30px;
    }

    .right-navigation-menu .account-wrap:not(:first-child) {
        padding-top: 16px;
    }

    .right-navigation-menu .signout {
        margin-top: 16px;
    }

    .right-navigation-menu .menu-bottom .menu-link {
        margin: 0 0 10px 0;
    }

    .account-wrapper ul li {
        padding: 10px 10px;
    }
}

/* // Medium devices (tablets, less than 992px) */
@media (min-width: 992px) {
    .settings-header::-webkit-scrollbar {
        height: 2px;
    }

    .settings-header::-webkit-scrollbar-track {
        background: #171717;
        border-radius: 10px;
    }

    .settings-header::-webkit-scrollbar-thumb {
        background: var(--white-color);
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        width: 4px;
    }

    ::-webkit-scrollbar-track {
        background: #171717;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--white-color);
        border-radius: 10px;
    }
}

@media (max-width: 991.98px) {
    .user-permissions .create-user .create-user-wrapper .theme-form-wrapper .find-button-wrap {
        width: 100%;
        margin-left: 0;
        padding-top: 10px;
    }

    .user-permissions .create-user .create-user-wrapper .theme-form-wrapper .find-button-wrap > button {
        width: 25%;
    }
    .group-camera-bodys {
        grid-template-columns: 50% 50%;
    }

    .auth-form-wrapper .form-heading h3 {
        font-size: 28px;
    }

    .dark-theme .camera-view .camera-row .card-camera .card-subhead .text-wrapper .mata-details .title {
        border-right: 1px solid #171717;
        border-bottom: 1px solid #171717;
        padding: 15px 15px;
    }

    .camera-view .camera-row .card-camera .card-camera-body {
        padding-bottom: calc(100vh - 348px);
    }

    .camera-view .camera-row .card-camera .card-header button svg {
        width: 16px;
        height: 16px;
    }

    .details-view .add-new-device-page .theme-form-wrapper .form {
        max-width: 100%;
    }

    .form-control {
        font-size: 14px;
    }

    select.form-select {
        font-size: 14px;
    }

    .auth-form-wrapper .form-text p, .auth-form-wrapper .outer-form-text p {
        font-size: 14px;
    }

    .auth-form-wrapper .button-wrap {
        margin: 0 0 20px 0;
    }

    .mobile-header {
        padding: 15px 15px;
    }

    .camera-row .card-camera .card-header .camera-details .dropdown-menu .notification-footer .btn {
        padding: 10px 15px;
        max-width: 180px;
    }

    .left-navigation-menu {
        display: none;
    }

    .site-main-wrapper .camera-row .card-camera .card-header .camera-details .dropdown-menu ul li .dropdown-item {
        font-size: 12px;
    }

    .camera-row .card-camera .card-header .camera-details .dropdown-menu .notif-header {
        font-size: 14px;
        padding: 12px;
    }

    .camera-row .card-camera .card-header .camera-details .dropdown-menu .notification-footer {
        padding: 15px;
    }

    .btn {
        font-size: 14px;
    }

    .main-wrapper .camera-list-wrapper {
        padding: 20px 15px;
        padding-bottom: 50px;
    }

    .mobile-header .left-wrap, .mobile-header .search-profile-wrap {
        flex: 1 0;
    }

    .mobile-header .left-wrap .logo-wrap {
        padding: 0;
        border: none;
    }

    .mobile-header .add-device-wrap .add-device {
        margin: 0;
        padding: 6px 8px;
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle {
        width: 25px;
        height: 25px;
        border: none;
        background: transparent;
        position: relative;
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle .hamburger {
        width: 25px;
        height: 2px;
        background: #000;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12.5px;
        margin-top: -1.5px;
        transition: transform 200ms;
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle .hamburger.hamburger-1 {
        transform: translate3d(0, -8px, 0);
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle .hamburger.hamburger-2 {
        transform: translate3d(0, 0, 0);
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle .hamburger.hamburger-3 {
        transform: translate3d(0, 8px, 0);
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle.open .hamburger-1 {
        transform: translate3d(0, 0, 0) rotate(45deg);
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle.open .hamburger-2 {
        transform: translate3d(0, 0, 0) scale(0.1, 1);
    }

    .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle.open .hamburger-3 {
        transform: translate3d(0, 0, 0) rotate(-45deg);
    }

    .body-overlay {
        top: 0;
        height: calc(100vh);
    }

    .mobile-header .search-profile-wrap .search-wrap {
        max-width: inherit;
        min-width: inherit;
    }

    .mobile-header .add-device-wrap .add-device svg {
        margin-right: 4px;
    }


    .mobile-header .search-profile-wrap .search-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
    }

    .mobile-header .search-profile-wrap {
        position: relative;
    }

    .mobile-header .search-profile-wrap .search-wrap .btn {
        right: 0;
        padding: 0;
        transition: .3s;
    }

    .mobile-header .add-device-wrap .add-device {
        font-size: 12px;
    }

    .mobile-header .search-profile-wrap .search-wrap.active {
        width: calc(100vw - 170px);
    }

    .mobile-header .search-profile-wrap .search-wrap .form-control {
        font-size: 12px;
        padding: 11px 15px;
    }

    .mobile-header .search-profile-wrap .search-wrap.active .btn {
        right: 10px;
    }

    .dark-theme .mobile-header .search-profile-wrap .profile-wrap .sideb-toggle .hamburger {
        background: #fff;
    }

    .devices-page .camera-header .dropdown .btnn {
        padding: 0;
        background: transparent !important;
    }

    .devices-page .camera-header .group-dropdown .btn,
    .site-map-view .camera-header .group-dropdown .btn {
        font-weight: 700;
        font-size: 16px;
        color: #3BB44A;
        display: flex;
        align-items: center;
    }

    .devices-page .camera-header .group-dropdown .btn::after,
    .site-map-view .camera-header .group-dropdown .btn::after {
        border-top: 0.4em solid #949494;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
    }

    .left-navigation-menu {
        display: none;
    }

    .fixed-footer {
        background: #FFFFFF;
        box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 401;
        padding: 10px 15px;
        height: 70px;
    }

    .fixed-footer .side-navigation-panel .side-navigation {
        font-weight: 400;
        font-size: 14px;
        color: #484848;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
    }

    .dark-theme .fixed-footer .side-navigation-panel .side-navigation {
        color: #979797;
    }

    .fixed-footer .side-navigation-panel .side-navigation .icon {
        width: 30px;
        height: 30px;
        display: inline-block;
        background-size: contain;
        background-position: center;
    }

    .dark-theme .fixed-footer {
        background: #000000;
        border-top: 1px solid #383838;
        box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
    }


    .fixed-footer .icon.camera {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.49 7.80863V7.81V16.19C21.49 17.9106 20.9791 19.2238 20.0964 20.1064C19.2138 20.9891 17.9006 21.5 16.18 21.5H7.81C6.08945 21.5 4.77634 20.9891 3.89377 20.1054C3.01114 19.2217 2.5 17.9059 2.5 16.18V7.81C2.5 6.08944 3.01093 4.77618 3.89355 3.89355C4.77618 3.01093 6.08944 2.5 7.81 2.5H16.19C17.9107 2.5 19.2237 3.01097 20.105 3.89333C20.9861 4.77559 21.4947 6.08838 21.49 7.80863ZM12.3495 15.6433L12.3503 15.6429L13.6303 14.9029L14.9095 14.1633C14.9096 14.1633 14.9096 14.1632 14.9097 14.1632C15.8276 13.6346 16.3975 12.8678 16.3975 12C16.3975 11.1322 15.8276 10.3654 14.9097 9.83682C14.9096 9.83678 14.9096 9.83673 14.9095 9.83669L13.6303 9.09713L12.3503 8.35713L12.3495 8.35669C11.4328 7.82888 10.484 7.71686 9.73211 8.14899C8.97931 8.58164 8.6 9.45829 8.6 10.52V12V13.48C8.6 14.5377 8.97987 15.4132 9.73136 15.8468C10.4826 16.2803 11.4315 16.1718 12.3495 15.6433Z' stroke='%23484848'/%3E%3C/svg%3E");
    }


    .fixed-footer .icon.recordings {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.53 20.4201H6.21C3.05 20.4201 2 18.3201 2 16.2101V7.79008C2 4.63008 3.05 3.58008 6.21 3.58008H12.53C15.69 3.58008 16.74 4.63008 16.74 7.79008V16.2101C16.74 19.3701 15.68 20.4201 12.53 20.4201Z' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.5202 17.1001L16.7402 15.1501V8.84013L19.5202 6.89013C20.8802 5.94013 22.0002 6.52013 22.0002 8.19013V15.8101C22.0002 17.4801 20.8802 18.0601 19.5202 17.1001Z' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11C12.3284 11 13 10.3284 13 9.5C13 8.67157 12.3284 8 11.5 8C10.6716 8 10 8.67157 10 9.5C10 10.3284 10.6716 11 11.5 11Z' stroke='%23484848' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .fixed-footer .icon.groups {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.25 8V2.5H16.19C17.9106 2.5 19.2238 3.01093 20.1064 3.89355C20.9891 4.77618 21.5 6.08944 21.5 7.81V8V8.75V13.25H13.25V8.75V8Z' stroke='%23484848'/%3E%3Cpath d='M2.5 15.25V10.75H10.75V15.25V15.75V21.5H7.81C6.08944 21.5 4.77618 20.9891 3.89355 20.1064C3.01093 19.2238 2.5 17.9106 2.5 16.19V15.75V15.25Z' stroke='%23484848'/%3E%3Cpath d='M10.75 2.5V8.25H2.5V7.81C2.5 6.08944 3.01093 4.77618 3.89355 3.89355C4.77618 3.01093 6.08944 2.5 7.81 2.5H10.75Z' stroke='%23484848'/%3E%3Cpath d='M21.5 15.75V16.19C21.5 17.9106 20.9891 19.2238 20.1064 20.1064C19.2238 20.9891 17.9106 21.5 16.19 21.5H13.25V15.75H21.5Z' stroke='%23484848'/%3E%3C/svg%3E");
    }

    .fixed-footer .side-navigation-panel .side-navigation.selected .icon.camera {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM14.66 13.73L13.38 14.47L12.1 15.21C10.45 16.16 9.1 15.38 9.1 13.48V12V10.52C9.1 8.61 10.45 7.84 12.1 8.79L13.38 9.53L14.66 10.27C16.31 11.22 16.31 12.78 14.66 13.73Z' fill='%233BB44A'/%3E%3C/svg%3E");
    }

    .fixed-footer .side-navigation-panel .side-navigation.selected .icon.recordings {

        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.53 20.4201H6.21C3.05 20.4201 2 18.3201 2 16.2101V7.79008C2 4.63008 3.05 3.58008 6.21 3.58008H12.53C15.69 3.58008 16.74 4.63008 16.74 7.79008V16.2101C16.74 19.3701 15.68 20.4201 12.53 20.4201Z' fill='%233BB44A'/%3E%3Cpath d='M19.5202 17.1001L16.7402 15.1501V8.84013L19.5202 6.89013C20.8802 5.94013 22.0002 6.52013 22.0002 8.19013V15.8101C22.0002 17.4801 20.8802 18.0601 19.5202 17.1001Z' fill='%233BB44A'/%3E%3Cpath d='M11.5 11C12.3284 11 13 10.3284 13 9.5C13 8.67157 12.3284 8 11.5 8C10.6716 8 10 8.67157 10 9.5C10 10.3284 10.6716 11 11.5 11Z' fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .fixed-footer .side-navigation-panel .side-navigation.selected .icon.groups {

        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.19 2H12.75V8V8.75V13.75H22V8.75V8V7.81C22 4.17 19.83 2 16.19 2Z' fill='%233BB44A'/%3E%3Cpath d='M2 10.25V15.25V15.75V16.19C2 19.83 4.17 22 7.81 22H11.25V15.75V15.25V10.25H2Z' fill='%233BB44A'/%3E%3Cpath d='M11.25 2V8.75H2V7.81C2 4.17 4.17 2 7.81 2H11.25Z' fill='%233BB44A'/%3E%3Cpath d='M22 15.25V16.19C22 19.83 19.83 22 16.19 22H12.75V15.25H22Z' fill='%233BB44A'/%3E%3C/svg%3E");
    }

    .fixed-footer .side-navigation-panel .side-navigation.selected {
        font-weight: 600;
        color: #000;
    }

    .dark-theme .fixed-footer .side-navigation-panel .side-navigation.selected {
        color: #fff;
    }

    .site-main-wrapper .main-wrapper .main-page-height {
        min-height: calc(100vh - 80px);
    }

    .camera-row .card-camera .card-header .camera-status {
        border-right: none;
    }

    .camera-row .card-camera .card-header .camera-details {
        padding: 12px 10px 12px 0px;
    }

    .camera-row .card-camera .card-header .camera-details .btn-notif.ms-1 {
        margin-left: 5px !important;
    }

    .site-header .display-wrap {
        display: none !important;
    }

    .site-header .search-profile-wrap {
        flex-grow: 1 !important;
    }

    .theme-form-wrapper .d-flex.mb__20 {
        display: block !important;
    }

    .add-new-device-page .theme-form-wrapper, .setting-page-wrapper .profile-wrapper .account-form {
        padding: 0;
    }

    .theme-form-wrapper .col-form-label.text-end {
        text-align: left !important;
        padding: 0;
        margin: 0 0 10px 0 !important;
    }

    .add-new-device-page .theme-form-wrapper .flex-grow-1, .theme-form-wrapper .flex-grow-1, .profile-wrapper .theme-form-wrapper .flex-grow-1, .add-modal .modal-dialog .modal-content .modal-body .theme-form-wrapper .flex-grow-1 {
        padding-left: 0;
    }

    .add-new-device-page .map-wrap {
        width: 100%;
        height: 100%;
        min-height: 400px;
    }

    .add-new-device-page .map-wrap iframe {
        min-height: 400px;
    }

    .theme-form-wrapper .sync-device-checkbox .form-check-label {
        margin-left: 0;
    }

    .add-new-device-page {
        padding-bottom: 90px;
    }

    .add-new-device-page .theme-form-wrapper .btn {
        max-width: 180px;
    }

    .content-page .page-title h1, .content-page .section-title h2 {
        font-size: 20px;
    }

    .content-page .page-title {
        padding: 20px 15px;
    }

    .content-page .content-page-wrapper {
        padding: 40px 0 90px;
    }

    .content-page .faq-accordion-wrap .accordion-item .accordion-button, .content-page .report-checklist .form-check .form-check-label h6 {
        font-size: 16px;
    }

    .content-page .section-title p {
        font-size: 14px;
    }

    .content-page .report-checklist .form-check .form-check-label::before {
        width: 18px;
        height: 18px;
    }

    .content-page .report-checklist .form-check .form-check-label {
        padding-left: 2rem;
    }

    .content-page .section-faq, .content-page .section-report {
        margin-bottom: 30px;
    }

    .validation-msg {
        font-size: 13px;
    }

    /*******Groups Screen*******/
    .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme {
        position: fixed;
        left: -100%;
        width: 100%;
        top: 70px;
        transition: .3s;
        z-index: 3;
    }

    .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme.groups-sidebar {
        width: 100%;
    }

    .groups-list-wrapper .accordion-item .accordion-button {
        font-size: 14px;
        padding: 10px 15px;
    }

    .groups-list-wrapper .accordion-item .camera-wrap .left-side {
        margin-right: 15px;
    }

    .site-main-wrapper .main-wrapper .main-page-height .sidebar-theme.active {
        left: 0;
        min-height: calc(100vh - 140px);
    }

    .modal-delete .text-wrap h4 {
        font-size: 20px;
    }

    .modal-delete .modal-content .modal-body {
        padding: 25px 25px;
    }

    .modal-delete .text-wrap .note {
        font-size: 14px;
        padding: 20px 20px;
        border-left-width: 5px;
    }

    .modal-delete .text-wrap .note .heading {
        font-size: 20px;
    }

    .modal-delete .text-wrap .note .heading svg {
        width: 30px;
        height: 30px;
    }

    .modal-delete .button-wrap .btn {
        min-width: 150px;
        font-size: 14px;
    }

    .mobile-title-bar {
        position: relative;
        min-height: 60px;
    }

    .mobile-title-bar .back-btn {
        left: 20px;
        position: absolute;
    }

    .mobile-title-bar .back-btn svg {
        width: 24px;
        height: 24px;
    }

    .mobile-title-bar .available-heading {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
    }

    .mobile-title-bar .available-heading h3 {
        padding: 0 !important;
    }

    .sidebar-theme .sidebar-wrapper .camera-switch-wrap {
        padding: 25px 25px 10px 25px;
    }

    .sidebar-theme .sidebar-wrapper .camera-switch-wrap .check-switch {
        background: #DADADA;
        border: 1px solid #C7C7C7;
    }

    .sidebar-theme .sidebar-wrapper .camera-switch-wrap .check-switch .form-check .form-check-label {
        color: #4F4F4F;
    }

    .dark-theme .sidebar-theme .sidebar-wrapper .camera-switch-wrap .check-switch {
        background: #171717;
        border: 1px solid #000000;
    }

    .devices-page .camera-header,
    .site-map-view .camera-header {
        padding: 10px 15px;
    }

    .btn {
        padding: 10px 15px;
    }

    .content-page .section-form .form-wrapper .button-wrap {
        text-align: center;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area::before {
        display: none;
    }

    .setting-page-wrapper {
        padding: 25px 0 90px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area {
        padding: 0px 0px 20px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area .user-icon {
        margin-bottom: 40px;
    }

    .account-details-form.profile-wrapper .theme-form-wrapper .flex-grow-1 {
        max-width: inherit;
        padding: 0;
    }

    ul.accordion-menu {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    ul.accordion-menu .dropdown-item {
        border-bottom: 1px solid #DADADA;
        padding-left: 0;
        padding-right: 0;
        color: #484848;
        font-size: 14px;
    }

    .dark-theme ul.accordion-menu .dropdown-item {
        border-color: rgba(255, 255, 255, 0.11);
        color: #686868;
    }

    ul.accordion-menu li:last-child .dropdown-item {
        border: none;
    }

    ul.accordion-menu .dropdown-item.active {
        color: #3BB44A;
        background-color: transparent;
    }

    .sidebar-theme .sidebar-wrapper .all-cameras-wrapper .accordion-button {
        padding: 10px 10px;
    }

    .sidebar-theme .sidebar-wrapper {
        max-height: calc(100vh - 137px);
    }

    .devices-page .camera-header.details-header .available-heading {
        padding-left: 15px;
    }

    .details-view .add-new-device-page .theme-form-wrapper {
        padding: 20px;
    }

    .details-view .add-new-device-page {
        padding-bottom: 70px;
    }

    .details-view .add-new-device-page.notification-list {
        height: auto;
    }

    .details-view .live-vew-page .card-camera-body {
        height: calc(100vh - 265px);
        position: relative;
    }

    .terms-row .sidebar-left {
        display: none;
    }

    .terms-row .flex-grow-1 {
        max-width: 100%;
    }

    .details-view .add-new-device-page.notification-list {
        padding-bottom: 90px;
    }

    .notes-view-page {
        padding: 15px 15px 35px 15px !important;
    }

    .messageModal .modal-dialog .modal-content .modal-body {
        padding: 30px;
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .messageModal .modal-dialog .modal-content .modal-text .icon-wrap {
        width: 100px;
        height: 100px;
        border-width: 4px;
    }

    .camera-view .camera-row .card-camera .card-camera-body {
        padding-bottom: 58%;
    }

    .details-view .live-vew-page .card-camera-body {
        height: calc(100vh - 231px);
        position: relative;
    }

    .report-analytics .report-analytics-wrap .icon svg {
        width: 20px;
        height: 20px;
    }

    .report-analytics .report-analytics-wrap {
        font-size: 14px;
    }

    .messageModal .modal-dialog .modal-content .modal-text h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .messageModal .modal-dialog .modal-content .modal-text p {
        font-size: 14px;
    }

    .messageModal .modal-dialog .modal-content .modal-text.success .icon-wrap svg {
        width: 50px;
    }

    .add-modal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 18px;
    }

    .modal-delete .text-wrap h4 {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .modal-delete .text-wrap .note p {
        font-size: 12px;
    }

    .modal-delete .text-wrap .note .heading {
        font-size: 16px;
    }

    .add-modal .modal-dialog .modal-content .modal-header .btn-close {
        width: 25px;
        height: 25px;
    }

    .add-modal .modal-dialog .modal-content .modal-body .button-wrap {
        padding-top: 0;
    }

    .add-modal .modal-dialog .modal-content .modal-body {
        padding: 15px;
    }

    .settings-header ul li a {
        padding: 10px 15px;
        gap: 8px;
    }

    .subscription-group .subscription-box .heading h4 {
        font-size: 16px;
    }

    .subscription-group .subscription-box {
        margin-bottom: 15px;
        padding: 20px;
    }

    .report-analytics .report-analytics-wrap {
        flex-direction: row-reverse;
        padding: 12px 0;
    }

    .report-analytics .report-analytics-wrap .title {
        padding-left: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .user-permissions .create-user .create-user-wrapper {
        padding: 15px;
    }

    .user-permissions .create-user .create-user-wrapper .theme-form-wrapper .form-control {
        font-size: 14px;
        padding: 10px 13px;
    }

    .user-permissions .page-heading h3 {
        font-size: 18px;
    }

    .devices-storage-wrapper .devices-storage-list {
        margin-bottom: 15px;
        padding: 15px;
    }

    .devices-storage-wrapper .devices-storage-list .data-list {
        padding-left: 5px;
        padding-right: 5px;
    }

    .subscription-group .subscription-box .heading {
        padding-bottom: 10px;
    }

    .subscription-group .subscription-box .data-wrapper {
        margin: 0 0 20px 0;
    }

    .settings-header ul li:not(:last-of-type) {
        margin-right: 5px;
    }

    .settings-header ul li a svg {
        width: 18px;
        height: 18px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area .user-icon img {
        width: 150px;
        height: 150px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area .user-icon label {
        height: 35px;
        width: 35px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area .user-icon {
        margin-bottom: 20px;
    }

    .setting-page-wrapper .profile-wrapper .profile-image-area .user-icon label svg {
        width: 20px;
        height: 20px;
    }

    .site-header .logo-wrap .camera-dropdown, .devices-page .camera-header .available-heading h2 {
        font-size: 16px;
    }

    .devices-page .camera-header .button-wrap .btn-delete {
        height: 28px;
        width: 28px;
        padding: 5px;
    }

    .devices-page .camera-header .available-heading .add-btn svg {
        width: 28px;
        height: 28px;
    }

    .theme-form-wrapper .sync-device-checkbox .form-check-label {
        padding-top: 60px;
        padding-left: 35px;
        background-position: left top;
    }

    .theme-form-wrapper .sync-device-checkbox .form-check-label::before {
        left: 0;
    }

    .add-new-device-page {
        padding: 20px 15px 90px;
    }

    .auth-header {
        padding: 15px;
    }

    .devices-page .add-device {
        font-size: 16px;
        width: 190px;
        height: 190px;
    }

    .devices-page .add-device svg {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
    }

    .auth-form-wrapper .form-wrapper {
        margin: 0 auto 20px;
        padding: 15px;
    }

    .auth-form-wrapper .form-heading h3 {
        font-size: 22px;
    }

    .auth-form-wrapper .button-wrap {
        margin: 0 0 14px 0;
    }

    .auth-form-wrapper .form-heading {
        margin: 0 0 20px 0;
    }

    .form-control {
        padding: 10px 13px;
    }

    body, .custom-password-checklist li, .auth-form-wrapper .form-text p, .custom-password-checklist::before, .form-control {
        font-size: 14px;
    }

    .custom-password-checklist {
        padding: 10px 10px !important;
    }

    .auth-form-wrapper {
        padding: 20px 0;
    }

    .camera-row .card-camera .card-header .camera-details .dropdown-menu {
        width: 260px;
    }

    .mobile-header .search-profile-wrap .search-wrap.active {
        width: calc(100vw - 170px);
    }

    .details-view .header-tabs li.nav-item .nav-link::before {
        height: 2px;
    }

    .details-view .header-tabs li.nav-item .nav-link {
        font-size: 16px;
        padding: 12px 15px;
    }

    .nav-head {
        padding: 0px 15px;
        background: #FFFFFF;
        overflow-x: auto;
        max-width: 100vw;
    }

    .dark-theme .nav-head {
        background: #000;
    }

    .nav-head .header-tabs {
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    .nav-head .header-tabs::after {
        content: "";
        display: block;
        width: 75px;
        height: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #ffffff);
        pointer-events: none;
    }

    .dark-theme .nav-head .header-tabs::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), #171717);
    }

    .details-view .header-tabs li.nav-item:last-child {
        padding-right: 50px;
    }

    .notification-view-page .notification-list.add-new-device-page .notification-wrapper {
        flex-direction: column;
    }

    .notification-view-page .notification-list.add-new-device-page .notification-wrapper .text {
        order: 1;
        padding: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100vw;
    }

    .dark-theme .notification-view-page .notification-list.add-new-device-page .notification-wrapper.active .text {
        white-space: initial;
    }

    .notification-view-page .notification-list.add-new-device-page .notification-wrapper .timecount {
        display: none;
    }

    .notification-view-page .notification-list.add-new-device-page .notification-wrapper .timestamp {
        order: 2;
        margin: 0;
        font-size: 12px;
    }

    .details-view .add-new-device-page.notification-list {
        max-width: 100vw;
        padding-left: 15px;
        padding-right: 15px;
    }

    .notes-view-page .card {
        margin-bottom: 20px;
    }

    .notes-view-page .addCard .note.theme-form-wrapper {
        padding: 15px;
    }

    .notes-view-page .addCard .note.theme-form-wrapper .add-card-empty {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;
        gap: 6px;
    }

    .notes-view-page .addCard .note .icon-wrap {
        margin: 0;
        height: 15px;
    }

    .notes-view-page .addCard .note .title {
        font-size: 16px;
        color: #fff;
    }

    .notes-view-page .addCard .note .icon-wrap svg {
        width: 15px;
        height: 15px;
        vertical-align: top;
    }

    .notes-view-page .addCard {
        margin-bottom: 20px;
    }

    .notes-view-page .addCard .note.theme-form-wrapper .title-field {
        font-size: 14px;
    }

    .notes-view-page .addCard .note.theme-form-wrapper .description {
        font-size: 14px;
    }
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .group-camera-bodys {
        grid-template-columns: 100%;
    }

    .auth-header .logo img {
        max-width: 105px;
    }

    .modal-delete .text-wrap .note {
        padding: 15px 15px 15px 15px;
    }

    .details-view .header-tabs li.nav-item {
        width: auto;
    }

    .modal-delete .text-wrap {
        margin: 0 0 20px 0;
    }

    .right-navigation-menu {
        width: 100%;
    }

    .modal-delete .button-wrap .btn {
        min-width: 120px;
    }

    .modal-delete .button-wrap .btn+.btn {
        margin-left: 10px;
    }

    .modal-delete .modal-content .modal-body {
        padding: 16px;
    }
}